import React from 'react'
import { useLazyQuery } from '@apollo/client'
import { ACTIVATE_USER } from '../../graphql'

import ForwardingSteps from './forwardingsteps'
import Button from '../button'
import Verify from '../verify'

function NewsletterSetup(props) {
  const { user } = props
  const [activateUser, { loading }] = useLazyQuery(ACTIVATE_USER)

  const isPro = user.payment.premium_status === 'pro'
  const isGmail = user.is_user_gmail

  return (
    <section>
      {isPro && isGmail ? (
        <>
          <p className="text-xl mb-4">
            <span role="img" aria-label="party popper" className="mr-2">
              🎉
            </span>
            Your Kindle has been connected and your Gmail forwarding filters
            have been synced.
          </p>
          <Button onClick={activateUser} loading={loading}>
            Finish setup
          </Button>
        </>
      ) : (
        <>
          <h3 className="text-xl">Setting up Gmail Forwarding</h3>
          <ForwardingSteps phase={2} user={user} className="my-8" />
          <Verify onSubmit={activateUser} loading={loading} />
        </>
      )}
    </section>
  )
}

export default NewsletterSetup
