import React from 'react'
import { useQuery } from '@apollo/client'
import { GET_USER } from '../graphql'

import { Button, Dashboard, Error, Link, Loading } from '../components'

import DancingDoodle from '../assets/images/doodles/DancingDoodle.png'

function Ready() {
  const { data, loading, error } = useQuery(GET_USER)
  if (error) {
    return <Error error={error} />
  }
  const user = data?.getUser
  if (!user || loading) {
    return <Loading />
  }
  const { newsletters } = user
  return (
    <Dashboard
      title="Onboarding"
      header="Ready to go!"
      description="Your newsletters are added and your account is active"
    >
      <section>
        <p className="text-2xl">
          You have added <b>{newsletters.length}</b> newsletter
          {newsletters.length !== 1 ? 's' : ''}.
        </p>
        <p className="my-4 text-accent">
          The next time a newsletter is sent to you, it&apos;ll automatically
          appear on your Kindle.
        </p>
        <Link to="/">
          <Button>View dashboard</Button>
        </Link>
        <p className="mt-2 text-xs text-accent">
          Enjoy the service and want to share your support?{' '}
          <Link href="https://ctt.ec/LR0Oe" className="link">
            Tweet it
          </Link>{' '}
          and help us spread the word.
        </p>
      </section>
      <section className="mt-8">
        <img
          src={DancingDoodle}
          className="w-full max-w-lg"
          alt="dancing doodle"
        />
      </section>
    </Dashboard>
  )
}

export default Ready
