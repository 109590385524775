import React from 'react'
import { Dashboard } from '.'

export default (props) => {
  const { error } = props
  return (
    <Dashboard title="Error">
      <div className="absolute top-0 left-0 w-full h-screen flex justify-center items-center">
        <div className="text-center">
          <h1 className="text-4xl mb-4">Something went wrong</h1>
          <p className="text-xl">We apologize for the inconvenience.</p>
          {error?.message && (
            <p className="mt-2 error-status">Error: {error.message}</p>
          )}
        </div>
      </div>
    </Dashboard>
  )
}
