import React, { useState } from 'react'
import Tippy from '@tippyjs/react'

import Button from './button'

function Verify(props) {
  const { onSubmit, loading = false, className } = props
  const [checked, setChecked] = useState(false)

  async function handleSubmit(e) {
    e.preventDefault()
    onSubmit()
  }

  return (
    <form className={className} onSubmit={handleSubmit}>
      <div className="flex items-center">
        <input
          type="checkbox"
          className="text-blue-500 rounded"
          value={checked}
          checked={checked}
          onChange={() => {
            setChecked(!checked)
          }}
        />
        <Tippy
          content="Click the checkbox on the left to continue"
          disabled={checked}
        >
          <div className="ml-2">
            <Button
              className="text-sm sm:text-base"
              disabled={!checked}
              loading={loading}
              type="submit"
            >
              I have completed the steps above
            </Button>
          </div>
        </Tippy>
      </div>
    </form>
  )
}

export default Verify
