export function getOrigin(subscribedDefault, subscribedEmail) {
  // if the user used the dropdown of defaults
  let origin = 'Dropdown'
  if (!subscribedDefault && !subscribedEmail) {
    // if user added the email via the text input
    origin = 'Manual input'
  } else if (subscribedEmail) {
    // if user received the email via the provided email address
    origin = 'Provided N2K Email'
  }
  return origin
}
