import React from 'react'
import { useMutation } from '@apollo/client'
import { SET_ONBOARDING_PHASE } from '../../graphql'

import Plan from '../plan'

function Pricing(props) {
  const { phase, user } = props
  const [setOnboardingPhase, { loading }] = useMutation(SET_ONBOARDING_PHASE)

  async function handleClick() {
    await setOnboardingPhase({
      variables: {
        phase: phase + 1,
      },
    })
  }

  if (
    user.payment.premium_status !== 'free' &&
    process.env.NODE_ENV !== 'development'
  ) {
    handleClick()
  }

  return (
    <section>
      <div className="lg:grid grid-cols-2 gap-2">
        <Plan
          payment={user.payment}
          hideChild
          variant="plus"
          baseUrl="/onboarding"
          additionalFeatures={[
            `Set up auto-forwarding on your email client (5-10 min). We'll guide you through it`,
          ]}
        />
        <Plan payment={user.payment} variant="pro" baseUrl="/onboarding" />
      </div>
      <div className="mt-8 text-xs flex items-center justify-end">
        <button
          className="link text-accent focus:outline-none"
          onClick={handleClick}
          disabled={loading}
        >
          No thanks, I&apos;d like to try for free
        </button>
      </div>
    </section>
  )
}

export default Pricing
