import React, { useState } from 'react'
import { useQuery, useMutation } from '@apollo/client'
import { GET_DEFAULTS, GET_USER, ADD_NEWSLETTERS } from '../graphql'

import Select from 'react-select'
import { validate } from 'email-validator'

import Button from './button'
import Dialog from './dialog'
import ForwardingSteps from './onboarding/forwardingsteps'

function InputField(props) {
  const { onSubmit, loading } = props
  const [open, setOpen] = useState(false)
  const { loading: loadingD, data } = useQuery(GET_DEFAULTS)
  const { loading: loadingU, data: dataU } = useQuery(GET_USER)
  const [addNewsletters, { loading: loadingSD }] = useMutation(ADD_NEWSLETTERS)

  const [selected, setSelected] = useState([])
  const [email, setEmail] = useState('')
  const [error, setError] = useState(null)

  const defaults = data?.getDefaults || []
  const user = dataU?.getUser
  const newsletters = user?.newsletters || []
  const isGmail = user?.is_user_gmail === true

  const emails = newsletters.map(({ source_email }) => source_email)
  const options = []
  defaults.forEach(({ id, source_email, name, is_paid }) => {
    if (emails.includes(source_email)) {
      // if you're already added it, skip it
      return
    }
    options.push({
      value: id,
      email: source_email,
      label: `${name} <${source_email}>${is_paid ? ' *' : ''}`,
      type: 'list',
    })
  })

  function toggleOpen() {
    setOpen(!open)
  }

  async function handleSubmit(e) {
    e.preventDefault()
    if (email.length > 0 && validate(email)) {
      if (!emails.includes(email)) {
        setSelected([
          ...selected,
          { value: email, email, type: 'manual', label: email },
        ])
        setError(null)
        setEmail('')
      } else {
        setError('Already subscribed to this newsletter')
      }
    } else {
      setError('Please enter a valid newsletter email')
    }
  }
  async function handleAdd(e) {
    e.preventDefault()
    const domainIds = []
    const manualEmails = []
    selected.forEach((domain) => {
      if (domain.type === 'manual') {
        manualEmails.push(domain.value)
      } else if (domain.type === 'list') {
        domainIds.push(domain.value)
      }
    })
    // TODO: update newsletters cache
    try {
      await addNewsletters({
        variables: {
          input: {
            domain_ids: domainIds.filter((v, i, a) => a.indexOf(v) === i),
            manual_emails: manualEmails.filter((v, i, a) => a.indexOf(v) === i),
          },
        },
      })
      setSelected([])
      setEmail('')
      if (onSubmit) {
        onSubmit()
      } else {
        toggleOpen()
      }
    } catch (e) {
      setError(e.message)
    }
  }
  return (
    <>
      <p className="text-xs mb-4">
        <span className="font-bold">IMPORTANT</span>: your email account must be
        subscribed to the newsletter <i className="underline">before</i> using
        this service. We do not subscribe for you.
      </p>
      <Select
        placeholder="Search for a newsletter..."
        options={options}
        isLoading={loadingD || loadingU}
        onChange={(value) => setSelected(value || [])}
        value={selected}
        isMulti
      />
      <p className="text-xs text-accent mt-2 text-right">* = paid newsletter</p>
      <form onSubmit={handleSubmit} className="my-8">
        <label className="label" htmlFor="manual">
          Don&apos;t see the one you want listed?
        </label>
        <div className="sm:flex items-center text-sm">
          <input
            type="email"
            name="manual"
            className="input w-full sm:w-72 text-sm"
            placeholder="Enter the email address..."
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Button className="mt-2 sm:mt-0 sm:ml-2" type="submit">
            Add to list
          </Button>
        </div>
        {error && <p className="mt-1 error-status">{error}</p>}
      </form>
      <Button
        className="w-full"
        onClick={handleAdd}
        disabled={selected.length === 0}
        loading={loadingSD || loading}
      >
        Add newsletters ({selected.length})
      </Button>
      <Dialog
        title={isGmail ? 'Success!' : 'Update Gmail Filter'}
        open={open}
        onClose={toggleOpen}
        actions={[<Button onClick={toggleOpen}>OK</Button>]}
      >
        {isGmail ? (
          `Added newsletter${
            selected.length > 1 ? 's' : ''
          } and synced filter with Gmail.`
        ) : (
          <ForwardingSteps
            user={user}
            phase={user.auto_confirmed === true ? 3 : null}
          />
        )}
      </Dialog>
    </>
  )
}

export default InputField
