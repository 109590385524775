export const ONBOARDING_PHASES = {
  '-1': 'Active',
  0: 'Naming',
  1: 'Kindle Setup',
  2: 'Newsletter Queue',
  3: 'Pricing',
  4: 'Gmail Setup',
  5: 'Newsletter Setup',
}

export const ONBOARDING_DESCRIPTIONS = {
  0: `We'll start off simple. You will need your Kindle, so grab that as well!`,
  1: `Before you start receiving newsletters, you need to sync your Kindle with our platform.`,
  2: `Now that we've connected your Kindle, let's set up some newsletters.`,
  3: `Before we keep going, let's find a pricing plan that works for you.`,
  4: `Let's connect with your email account now to configure the newsletter forwarding.`,
  5: `One more step, wrapping everything up.`,
}

export const PRICING = {
  premium: {
    title: 'Premium',
    text: 'purple-600',
    bg: 'purple-50',
    border: 'purple-600',
    cost: 9,
    child: 'plus',
    features: [
      'Integration with Gmail',
      'One-click newsletter setup',
      `"0 work option" - no additional setup needed`,
      'More features coming soon',
    ],
    numEmails: null,
  },
  plus: {
    title: 'Personal Plus',
    text: 'green-600',
    bg: 'green-50',
    border: 'green-600',
    cost: 5,
    child: 'free',
    features: [
      'Unlimited newsletters',
      'Convert documents to native Kindle format',
    ],
    numEmails: null,
  },
  pro: {
    title: 'Personal Pro',
    text: 'purple-600',
    bg: 'purple-50',
    border: 'purple-600',
    cost: 10,
    child: 'plus',
    features: [
      'Integration with Gmail',
      'One-click forwarding setup',
      `"0 work option" - no additional setup needed`,
      'More features coming soon',
    ],
    numEmails: null,
  },
  free: {
    title: 'Personal',
    text: 'blue-500',
    bg: 'blue-50',
    border: 'blue-500',
    cost: 0,
    features: [
      `${25} newsletters/month`,
      'No credit card required',
      'Dedicated email address',
    ],
    numEmails: 25,
  },
}

export const COLLECTIONS = [
  'cron',
  'deliveries',
  'domains',
  'emails',
  'newsletters',
  'oauth',
  'users',
  'additional_email_addresses',
]

// this needs to be kept in parity with: app/functions/utils/constants.js
export const FLAGS = {
  ENABLE_RSS: 'enable_rss',
  ENABLE_EMAIL_SUMMARY: 'enable_email_summary',
}
