import React, { useState } from 'react'

import { makeStyles } from '@material-ui/core/styles'

import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    flexGrow: 1,
  },
  secondaryHeading: {
    color: theme.palette.text.secondary,
  },
}))

export default ({
  title,
  children,
  expanded,
  setExpanded,
  defaultOpen = false,
  description,
}) => {
  const classes = useStyles()

  const [open, setOpen] = useState(defaultOpen)
  let useControlled = false
  if (expanded !== undefined && setExpanded !== undefined) {
    useControlled = true
  }

  return (
    <Accordion
      expanded={useControlled ? expanded : open}
      onChange={useControlled ? setExpanded : () => setOpen(!open)}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography className={classes.heading}>{title}</Typography>
        {description && (
          <Typography className={classes.secondaryHeading}>
            {description}
          </Typography>
        )}
      </AccordionSummary>
      <AccordionDetails>
        <div>{children}</div>
      </AccordionDetails>
    </Accordion>
  )
}
