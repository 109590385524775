import React from 'react'
import { loadStripe } from '@stripe/stripe-js'
import { useLazyQuery } from '@apollo/client'
import { CREATE_SESSION, CREATE_PORTAL } from '../graphql'

import Button from './button'
import { env } from '../utils'

const stripePromise = loadStripe(env.REACT_APP_STRIPE_PUBLISHABLE_KEY)

export default ({
  className,
  plan,
  ending,
  status,
  baseUrl = '/',
  children,
}) => {
  const [createSession, { data: dataC }] = useLazyQuery(CREATE_SESSION)
  const [createPortal, { data: dataP }] = useLazyQuery(CREATE_PORTAL)
  if (dataP?.createPortal) {
    window.location.assign(dataP.createPortal)
  }
  async function handleClick(isFree) {
    if (isFree) {
      await createSession({
        variables: {
          status,
          page: baseUrl,
        },
      })
    } else {
      await createPortal({
        variables: {
          page: baseUrl,
        },
      })
    }
  }
  if (dataC?.createSession) {
    const redirect = async () => {
      try {
        const stripe = await stripePromise
        const result = await stripe.redirectToCheckout({
          sessionId: dataC.createSession,
        })
        if (result.error) {
          throw new Error(result.error)
        }
      } catch (e) {
        console.error(e)
      }
    }
    redirect()
  }

  const isActive = plan === status
  const isFree = plan === 'free'

  function getButtonText() {
    if (isActive) {
      // plan is: active
      if (ending) {
        // plan is: active and ending
        return 'Renew Plan'
      } else {
        // plan is: active and not ending
        if (isFree) {
          // plan is: active, not ending, and free
          // (button should be disabled for this)
          return 'Current Plan'
        } else {
          // active, not ending, not free
          return 'Update Payment'
        }
      }
    } else {
      // plan is: not active
      if (isFree) {
        // plan is: not active and free
        return `Upgrade to ` + children
      } else {
        // plan is: not active and not free
        return `Change to ` + children
      }
    }
  }

  return (
    <Button
      role="link"
      onClick={() => handleClick(isFree)}
      className={className}
      disabled={isFree && isActive && !ending}
    >
      {getButtonText()}
    </Button>
  )
}
