import React, { useState } from 'react'
import { Redirect } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { GET_USER } from '../graphql'
import Papa from 'papaparse'

import { Dashboard, Loading, Button } from '../components'
import { COLLECTIONS, env } from '../utils'
import { auth } from '../utils/firebase'

const KEY = 'sql__query'

export default () => {
  const defaultValue = localStorage.getItem(KEY) || ''
  const [value, setValue] = useState(defaultValue)
  const [sql, setSQL] = useState({ data: null, error: null, loading: false })
  const { loading, data, error } = useQuery(GET_USER)
  const user = data?.getUser
  if (error) {
    return <Redirect to="/" />
  }
  if (!user || loading) {
    return <Loading />
  }
  if (!user.is_user_admin) {
    return <Redirect to="/" />
  }

  async function adminSql(options) {
    const { variables } = options
    const { query } = variables
    setSQL({ data: null, error: null, loading: true })
    try {
      const token = await auth.currentUser.getIdToken()
      const res = await fetch(
        `${env.REACT_APP_HEROKU_URL}/sql?query=${encodeURIComponent(query)}`,
        {
          headers: {
            authorization: token ? `Bearer ${token}` : '',
          },
        },
      )
      const { results } = await res.json()
      setSQL({ data: results, error: null, loading: false })
    } catch (e) {
      setSQL({ data: null, error: e, loading: false })
    }
  }

  async function handleSubmit(e) {
    e.preventDefault()
    if (value.length > 0) {
      localStorage.setItem(KEY, value)
      await adminSql({
        variables: {
          query: value,
        },
      })
    } else {
      localStorage.removeItem(KEY)
    }
  }

  const results = sql.data

  async function handleExport() {
    const csv = Papa.unparse(results)
    const blob = new Blob([csv])
    const a = document.createElement('a')
    a.href = URL.createObjectURL(blob, { type: 'text/csv' })
    a.download = 'export.csv'
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }

  return (
    <Dashboard title="SQL" header="SQL">
      <section>
        <form onSubmit={handleSubmit}>
          <label htmlFor="sql" className="label">
            SQL Query
          </label>
          <div className="flex items-center">
            <input
              name="sql"
              autoFocus
              placeholder="SELECT * from users"
              value={value}
              onChange={(e) => setValue(e.target.value)}
              className="input w-full"
            />
            <Button type="submit" className="ml-2" loading={sql.loading}>
              Submit
            </Button>
            <Button
              className="ml-2"
              onClick={(e) => {
                e.preventDefault()
                setValue('')
                localStorage.removeItem(KEY)
              }}
            >
              Clear
            </Button>
          </div>
          <p className="text-xs text-accent mt-1">
            <span className="uppercase">Available collections</span>:{' '}
            <b>{COLLECTIONS.join(', ')}</b>
          </p>
        </form>
      </section>
      <section className="p-8 card mt-4">
        {sql.loading && <p>Loading...</p>}
        {sql.error && <p className="error">Error: {sql.error.message}</p>}
        {sql.data && (
          <div>
            <div className="flex items-center justify-between">
              <h4 className="text-xl">{results.length} results</h4>
              <Button onClick={handleExport} disabled={results.length === 0}>
                CSV Download
              </Button>
            </div>
            <div style={{ maxHeight: 500 }} className="mt-2 overflow-y-scroll">
              {results.map((data, i) => {
                return (
                  <div
                    key={i}
                    className="border-t p-2 hover:bg-black hover:bg-opacity-5"
                  >
                    <h5 className="text-sm mb-1">
                      {i + 1}. {data.id}
                    </h5>
                    <ul className="list-disc text-xs">
                      {Object.keys(data).map((key, j) => {
                        return (
                          <li key={j} className="mb-0">
                            <b>{key}:</b> {JSON.stringify(data[key])}
                          </li>
                        )
                      })}
                    </ul>
                  </div>
                )
              })}
            </div>
          </div>
        )}
      </section>
    </Dashboard>
  )
}
