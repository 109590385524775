import React from 'react'

import { PRICING } from '../utils'

const Badge = ({ variant, children }) => {
  const variantClasses = React.useMemo(() => {
    const variantObj = PRICING[variant] ?? {
      bg: 'gray-100',
      border: 'gray-200',
      text: 'gray-900',
    }
    const { bg, border, text } = variantObj
    return `p-1 rounded text-xs border bg-${bg} border-${border} text-${text}`
  }, [variant])

  return <span className={variantClasses}>{children}</span>
}

export default Badge
