import { gql } from '@apollo/client'

import * as FRAGMENTS from './fragment'

// Users

export const SET_NAME = gql`
  mutation ($name: String!, $referral: String) {
    setName(name: $name, referral: $referral) {
      ...userFragment
    }
  }
  ${FRAGMENTS.USER_FRAGMENT}
`
export const SET_ONBOARDING_PHASE = gql`
  mutation ($phase: Int!) {
    setOnboardingPhase(phase: $phase) {
      ...userFragment
    }
  }
  ${FRAGMENTS.USER_FRAGMENT}
`
export const SET_IGNORE_ALERTS = gql`
  mutation ($value: Boolean!) {
    setIgnoreAlerts(value: $value) {
      ...userFragment
    }
  }
  ${FRAGMENTS.USER_FRAGMENT}
`
export const SET_PREPEND_SENDER = gql`
  mutation ($value: Boolean!) {
    setPrependSender(value: $value) {
      ...userFragment
    }
  }
  ${FRAGMENTS.USER_FRAGMENT}
`
export const ADD_ADDITIONAL_EMAIL_ADDRESS = gql`
  mutation ($emailAddress: EmailAddress!) {
    addAdditionalEmailAddress(emailAddress: $emailAddress) {
      ...userFragment
    }
  }
  ${FRAGMENTS.USER_FRAGMENT}
`
export const DELETE_ADDITIONAL_EMAIL_ADDRESS = gql`
  mutation ($emailAddress: EmailAddress!) {
    deleteAdditionalEmailAddress(emailAddress: $emailAddress) {
      ...userFragment
    }
  }
  ${FRAGMENTS.USER_FRAGMENT}
`

// Newsletters

export const UPDATE_NEWSLETTER = gql`
  mutation ($input: UpdateNewsletterInput!) {
    updateNewsletter(input: $input) {
      ...newsletterFragment
    }
  }
  ${FRAGMENTS.NEWSLETTER_FRAGMENT}
`
export const ADD_NEWSLETTERS = gql`
  mutation ($input: AddNewsletterInput!) {
    addNewsletters(input: $input) {
      ...userFragment
    }
  }
  ${FRAGMENTS.USER_FRAGMENT}
`
export const SEND_AGAIN = gql`
  mutation ($message_id: ID!, $send_to_admin: Boolean!) {
    sendAgain(message_id: $message_id, send_to_admin: $send_to_admin) {
      ...newsletterFragment
    }
  }
  ${FRAGMENTS.NEWSLETTER_FRAGMENT}
`

// Misc

export const SEND_CODE = gql`
  mutation ($email: EmailAddress!) {
    sendCode(email: $email)
  }
`
export const CONFIRM_CODE = gql`
  mutation ($code: String!, $email: EmailAddress) {
    confirmCode(code: $code, email: $email) {
      ...userFragment
    }
  }
  ${FRAGMENTS.USER_FRAGMENT}
`
export const INIT_GMAIL = gql`
  mutation ($code: String!, $sync_filters: Boolean) {
    initGmail(code: $code, sync_filters: $sync_filters) {
      ...userFragment
    }
  }
  ${FRAGMENTS.USER_FRAGMENT}
`

// RSS

export const ADD_RSS = gql`
  mutation ($rss: AddRssInput!) {
    addRss(rss: $rss) {
      ...userFragment
    }
  }
  ${FRAGMENTS.USER_FRAGMENT}
`

export const UPDATE_RSS = gql`
  mutation ($input: UpdateRssInput!) {
    updateRss(input: $input) {
      ...rssFragment
    }
  }
  ${FRAGMENTS.RSS_FRAGMENT}
`

// Admin

export const ADMIN_UPDATE_USER_FLAGS = gql`
  mutation ($flags: [String!]!) {
    adminUpdateUserFlags(flags: $flags) {
      ...userFragment
    }
  }
  ${FRAGMENTS.USER_FRAGMENT}
`

export const ADMIN_SEND_MESSAGE_TO_KINDLE = gql`
  mutation ($messageId: String!) {
    adminSendMessageToKindle(messageId: $messageId)
  }
`
