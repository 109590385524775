import React from 'react'

import Logo from '../assets/images/logo.png'

export default ({ className }) => {
  return (
    <p className={`font-thin flex items-center text-xl ${className}`}>
      <img className="h-12 w-12" src={Logo} alt="n2k logo" />
      <span className="mx-2">Newsletter to Kindle</span>
    </p>
  )
}
