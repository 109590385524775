import React from 'react'
import Tippy from '@tippyjs/react'
import { useMutation } from '@apollo/client'
import { UPDATE_NEWSLETTER } from '../graphql'

import { MoreVertical } from 'react-feather'
import { Menu, MenuDivider, MenuHeader, MenuItem } from '@szhsin/react-menu'

import Origin from './origin'
import Dialog from './dialog'
import Badge from './badge'
import Deliveries from './deliveries'

import { getOrigin } from '../utils'

function Newsletter(props) {
  const { user, newsletter, open, onSelect } = props
  const {
    id,
    source_email,
    name,
    is_newsletter_converted,
    is_newsletter_active,
    is_newsletter_hidden,
    blocked,
    subscribed_default,
    subscribed_email,
    delivery_count,
  } = newsletter

  // TODO: add `Save Changes`
  const [updateNewsletter] = useMutation(UPDATE_NEWSLETTER)

  const handleUpdate = async (id, key, value) => {
    await updateNewsletter({
      variables: {
        input: {
          newsletter_id: id,
          [key]: value,
        },
      },
    })
  }

  const status = user.payment.premium_status
  const isFree = status === 'free' || !status

  return (
    <>
      <div className="flex items-center text-sm border-b hover:bg-gray-50 focus:outline-none">
        <div
          className="flex-grow py-4 ml-2"
          onClick={() => onSelect()}
          role="button"
        >
          {name ? (
            <p className="font-bold">
              {name}
              <span className="ml-2 font-normal">&lt;{source_email}&gt;</span>
            </p>
          ) : (
            <p className="font-bold">{source_email}</p>
          )}
          <p className="text-xs text-accent">
            {delivery_count} email{delivery_count !== 1 && 's'} sent, {blocked}{' '}
            email
            {blocked !== 1 && 's'} blocked
          </p>
        </div>

        <div>{is_newsletter_hidden && <Badge>Hidden</Badge>}</div>

        <Menu
          menuButton={
            <button
              className="mr-2 btn-icon"
              onClick={(e) => e.stopPropagation()}
            >
              <MoreVertical />
            </button>
          }
          align="end"
          direction="bottom"
        >
          <MenuItem
            type="checkbox"
            checked={is_newsletter_converted}
            onClick={(e) =>
              handleUpdate(id, 'is_newsletter_converted', e.checked)
            }
            disabled={isFree}
          >
            <Tippy content="Upgrade plan to enable feature" disabled={!isFree}>
              <div>Convert to Kindle format</div>
            </Tippy>
          </MenuItem>
          <MenuItem
            type="checkbox"
            checked={is_newsletter_active}
            onClick={(e) => handleUpdate(id, 'is_newsletter_active', e.checked)}
          >
            Active newsletter
          </MenuItem>
          <MenuItem
            type="checkbox"
            checked={is_newsletter_hidden}
            onClick={(e) => handleUpdate(id, 'is_newsletter_hidden', e.checked)}
          >
            Hide from dashboard
          </MenuItem>
          <MenuDivider />
          <MenuHeader>
            <span className="mr-2">Via</span>
            <Origin>{getOrigin(subscribed_default, subscribed_email)}</Origin>
          </MenuHeader>
        </Menu>
      </div>
      <Dialog open={open} onClose={onSelect} title="Deliveries">
        <Deliveries
          user={user}
          columnId="newsletter_id"
          id={id}
          onSelect={onSelect}
        />
      </Dialog>
    </>
  )
}

export default Newsletter
