import React from 'react'

import Status from './status'
import UpgradeButton from './upgradebutton'

import { PRICING } from '../utils'

function Plan(props) {
  const {
    payment,
    variant,
    hideChild,
    additionalFeatures = [],
    baseUrl,
  } = props
  const { title, child, cost, features, bg, border, text } = PRICING[variant]
  return (
    <div
      className={`mb-4 lg:mb-0 border-2 bg-${bg} border-${border} card p-4 flex flex-col`}
    >
      <h3 className="text-2xl">
        <Status>{variant}</Status>
      </h3>
      <p className={`text-${text} text-sm mb-4`}>
        {cost > 0 ? `$${cost}/month` : `Free`}
      </p>
      {!hideChild && child && (
        <p className="mb-2">
          Everything in <Status>{child}</Status>, also:
        </p>
      )}
      <ul className="list-disc mb-4 flex-grow">
        {features.concat(additionalFeatures).map((feature, i) => {
          return <li key={i}>{feature}</li>
        })}
      </ul>
      <UpgradeButton
        status={variant}
        className={`text-white bg-${border} hover:bg-opacity-80`}
        ending={payment.ending}
        plan={payment.premium_status}
        baseUrl={baseUrl}
      >
        {title}
      </UpgradeButton>
    </div>
  )
}

export default Plan
