import React, { useState } from 'react'

import Link from '../link'
import Provided from '../provided'
import Copy from '../copy'

function First(props) {
  const { user } = props
  return (
    <ol className="list-decimal mb-8">
      <li>
        Visit{' '}
        <Link
          className="link break-words"
          href="https://mail.google.com/mail/u/0/#settings/fwdandpop"
        >
          https://mail.google.com/mail/u/0/#settings/fwdandpop
        </Link>
      </li>
      <li>
        Click <b>Add a forwarding address</b>
      </li>
      <li>
        Enter: <Provided>{user.unique_email_id}</Provided>
      </li>
      <li>
        Click <b>Next</b>
      </li>
      <li>
        Click <b>Proceed</b>
      </li>
      <li>
        Click <b>OK</b>
      </li>
    </ol>
  )
}

function Two(props) {
  const { user } = props
  const { newsletters } = user
  return (
    <ol className="list-decimal">
      <li>
        Click on the{' '}
        <b>
          <Link
            className="link"
            href="https://mail.google.com/mail/u/0/#settings/filters"
          >
            Filters and Blocked Addresses
          </Link>
        </b>
      </li>
      <li>
        Click <b>Create a new filter</b>
      </li>
      {newsletters.length > 0 ? (
        <li>
          Enter the line below in the <b>From</b> field:
          <br />
          <Copy>
            {newsletters
              .map((newsletter) => newsletter.source_email)
              .join(' OR ')
              .trim()}
          </Copy>
        </li>
      ) : (
        <li>
          Enter the email address of the newsletter in the <b>From</b> field
        </li>
      )}
      <li>
        Click <b>Create filter</b>
      </li>
      <li>
        Click the <b>Forward it to:</b> checkbox and select{' '}
        <b>{user.unique_email_id}@newslettertokindle.com</b>
      </li>
      <li>
        Click <b>Create filter</b>
      </li>
    </ol>
  )
}

function Second(props) {
  return (
    <>
      <p className="italic text-lg mb-8">
        Forwarding verification can take a moment, so refresh Gmail after a few
        seconds and then...
      </p>
      <Two {...props} />
    </>
  )
}

function Third(props) {
  const { user } = props
  const [forgot, setForgot] = useState(false)
  const { newsletters } = user
  if (forgot) {
    return <Two {...props} />
  }
  return (
    <ol className="list-decimal">
      <li>
        Click on the{' '}
        <b>
          <Link
            className="link"
            href="https://mail.google.com/mail/u/0/#settings/filters"
          >
            Filters and Blocked Addresses
          </Link>
        </b>
      </li>
      <li>
        Click <b>Edit</b> on the filter you created before
        <br />
        <button
          onClick={() => setForgot(true)}
          className="link text-accent focus:outline-none mt-2 mb-4"
        >
          I don&apos;t have an existing filter
        </button>
      </li>
      {newsletters.length > 0 ? (
        <li>
          Enter the line below in the <b>From</b> field:
          <br />
          <Copy>
            {newsletters
              .map((newsletter) => newsletter.source_email)
              .join(' OR ')
              .trim()}
          </Copy>
        </li>
      ) : (
        <li>
          Enter the email address of the newsletter in the <b>From</b> field
        </li>
      )}
      <li>
        Click <b>Continue</b>
      </li>
      <li>
        Click <b>Update filter</b>
      </li>
    </ol>
  )
}

function ForwardingSteps(props) {
  const { phase, className } = props
  return (
    <div className={className}>
      {(!phase || phase === 1) && <First {...props} />}
      {(!phase || phase === 2) && <Second {...props} />}
      {phase && phase === 3 && <Third {...props} />}
    </div>
  )
}

export default ForwardingSteps
