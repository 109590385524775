import React from 'react'
import { useQuery } from '@apollo/client'
import { GET_USER } from '../graphql'

import { Dashboard, Loading, Error } from '../components'
import { FLAGS } from '../utils'

export default () => {
  const { data, loading, error } = useQuery(GET_USER)
  if (error) {
    return <Error error={error} />
  }
  const user = data?.getUser
  if (!user || loading) {
    return <Loading />
  }

  return (
    <Dashboard
      title="Summaries"
      header="Summaries"
      description="LLM summaries of emails"
      flag={FLAGS.ENABLE_EMAIL_SUMMARY}
    >
      <section>
        <h3>TODO: add list of all summaries</h3>
      </section>
    </Dashboard>
  )
}
