import React from 'react'
import { Redirect } from 'react-router-dom'
import { useQuery, useMutation, useLazyQuery } from '@apollo/client'
import { DELETE_SELF, GET_USER, SET_ONBOARDING_PHASE } from '../graphql'

import {
  Dashboard,
  Error,
  Loading,
  Button,
  Naming,
  KindleSetup,
  NewsletterQueue,
  Pricing,
  GmailSetup,
  NewsletterSetup,
} from '../components'
import { ONBOARDING_DESCRIPTIONS, useAuth } from '../utils'

const TOTAL_PHASES = Object.keys(ONBOARDING_DESCRIPTIONS).length

function Phase(props) {
  const { phase } = props
  switch (phase) {
    case 0: {
      return <Naming {...props} />
    }
    case 1: {
      return <KindleSetup {...props} />
    }
    case 2: {
      return <NewsletterQueue {...props} />
    }
    case 3: {
      return <Pricing {...props} />
    }
    case 4: {
      return <GmailSetup {...props} />
    }
    case 5: {
      return <NewsletterSetup {...props} />
    }
    default: {
      return null
    }
  }
}

function Onboarding() {
  const { loading, data, error } = useQuery(GET_USER)
  const [deleteSelf, { loading: loadingD }] = useLazyQuery(DELETE_SELF)
  const [setOnboardingPhase] = useMutation(SET_ONBOARDING_PHASE)
  const { signOut } = useAuth()
  const user = data?.getUser
  if (error) {
    return <Error error={error} />
  }
  if (!user || loading) {
    return <Loading />
  }
  if (user.onboarding_phase === -1) {
    return <Redirect to="/ready" />
  }
  const phase = user.onboarding_phase

  async function dev__resetOnboarding(phase) {
    await setOnboardingPhase({
      variables: {
        phase,
      },
    })
  }

  async function handleDelete() {
    const answer = window.confirm(
      `Are you sure you'd like to delete your account?`,
    )
    if (answer) {
      await deleteSelf()
      await signOut()
    }
  }

  return (
    <Dashboard
      title="Onboarding"
      header="Getting Started"
      description={`(${phase + 1}/${TOTAL_PHASES}) ${
        ONBOARDING_DESCRIPTIONS[phase]
      }`}
    >
      <section>
        {process.env.NODE_ENV === 'development' && (
          <div className="flex items-center mb-4">
            <Button onClick={() => dev__resetOnboarding(0)}>[DEV] Reset</Button>
            <Button onClick={() => dev__resetOnboarding(phase - 1)}>
              [DEV] Back
            </Button>
            <Button onClick={() => dev__resetOnboarding(phase + 1)}>
              [DEV] Forward
            </Button>
          </div>
        )}
        <div className="p-6 sm:p-8 card">
          <Phase phase={phase} user={user} />
        </div>
        <div className="mt-2 text-sm text-right text-accent">
          On second thought, I&apos;m not interested.{' '}
          <button
            onClick={handleDelete}
            className="underline"
            disabled={loadingD}
          >
            Delete my account
          </button>
        </div>
      </section>
    </Dashboard>
  )
}

export default Onboarding
