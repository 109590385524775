import React, { useState } from 'react'
import { Redirect } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/client'
import {
  GET_USER,
  ADMIN_UPDATE_USER_FLAGS,
  ADMIN_SEND_MESSAGE_TO_KINDLE,
} from '../graphql'

import { Button, Dashboard, Loading, Toast } from '../components'
import { FLAGS } from '../utils'

const Flags = ({ flags }) => {
  const [adminUpdateUserFlags] = useMutation(ADMIN_UPDATE_USER_FLAGS)

  const config = Object.values(FLAGS).reduce((prev, curr) => {
    // `curr` is the string value of the flag
    return {
      ...prev,
      [curr]: flags.includes(curr),
    }
  }, {})

  const [localConfig, setConfig] = useState(config)

  async function handleSubmit(e) {
    e.preventDefault()

    const activeFlags = []
    Object.entries(localConfig).forEach(([key, value]) => {
      if (value) {
        activeFlags.push(key)
      }
    })

    await adminUpdateUserFlags({
      variables: {
        flags: activeFlags,
      },
    })
  }

  return (
    <form onSubmit={handleSubmit}>
      {Object.values(FLAGS).map((flag) => {
        return (
          <div key={flag}>
            <input
              type="checkbox"
              className="text-blue-500 rounded"
              value={localConfig[flag] === true}
              checked={localConfig[flag] === true}
              onChange={() => {
                setConfig((old) => ({ ...old, [flag]: !old[flag] }))
              }}
              name={`${flag}_flag`}
            />
            <label htmlFor={`${flag}_flag`}>{flag}</label>
          </div>
        )
      })}
      <Button
        type="submit"
        disabled={JSON.stringify(config) === JSON.stringify(localConfig)}
      >
        Save changes
      </Button>
    </form>
  )
}

const SendMessage = () => {
  const [adminSendMessageToKindle, { loading, error }] = useMutation(
    ADMIN_SEND_MESSAGE_TO_KINDLE,
  )

  const [messageId, setMessageId] = useState('')
  const [toast, setToast] = useState(false)

  async function handleSubmit(e) {
    e.preventDefault()

    if (messageId.length === 0) {
      return
    }

    await adminSendMessageToKindle({
      variables: {
        messageId,
      },
    })
    setMessageId('')
    setToast(true)
  }

  return (
    <form onSubmit={handleSubmit}>
      <label htmlFor="messageId" className="label">
        Message ID
      </label>
      <input
        className="input"
        value={messageId}
        onChange={(e) => {
          setMessageId(e.target.value)
        }}
        name="messageId"
        placeholder="Message ID from AWS..."
      />
      {error && <p className="mt-2 error-status">{error.message}</p>}

      <Button
        className="block mt-4"
        type="submit"
        loading={loading}
        disabled={messageId.length === 0}
      >
        Submit
      </Button>
      <Toast
        open={toast}
        onClose={() => setToast(false)}
        message="Sent message to your Kindle"
      />
    </form>
  )
}

export default () => {
  const { loading, data, error } = useQuery(GET_USER)
  const user = data?.getUser
  if (error) {
    return <Redirect to="/" />
  }
  if (!user || loading) {
    return <Loading />
  }
  if (!user.is_user_admin) {
    return <Redirect to="/" />
  }

  const { flags } = user

  return (
    <Dashboard title="Admin" header="Admin">
      <div className="space-y-4">
        <section>
          <h3 className="mb-2 text-xl">Flags</h3>
          <Flags flags={flags} />
        </section>
        <hr />
        <section>
          <h3 className="mb-2 text-xl">Send message</h3>
          <SendMessage />
        </section>
        <hr />
      </div>
    </Dashboard>
  )
}
