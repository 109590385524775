import React from 'react'
import { Redirect, Route, useHistory } from 'react-router-dom'
import { useApolloClient } from '@apollo/client'

import { auth, googleProvider, actionCodeSettings } from './firebase'

const TEMPORARY_EMAIL_STORAGE = 'emailForSignIn'

const AuthContext = React.createContext()
export const useAuth = () => React.useContext(AuthContext)
export const AuthProvider = ({ children }) => {
  const history = useHistory()
  const client = useApolloClient()
  const [firebaseUser, changeFirebaseUser] = React.useState(auth.currentUser)
  const [initializing, changeInitializing] = React.useState(true)

  auth.onAuthStateChanged((user) => {
    changeFirebaseUser(user)
    changeInitializing(false)
  })

  const isLoggedIn = !!firebaseUser
  const login = async (email, password = null, newUser = false) => {
    if (password) {
      if (newUser) {
        await auth.createUserWithEmailAndPassword(email, password)
      } else {
        await auth.signInWithEmailAndPassword(email, password)
      }
      history.push('/')
    } else {
      await auth.sendSignInLinkToEmail(email, actionCodeSettings)
      window.localStorage.setItem(TEMPORARY_EMAIL_STORAGE, email)
      window.alert('Please check your email for a log in link.')
    }
  }
  const loginGA = async () => {
    await auth.signInWithPopup(googleProvider)
    history.push('/')
  }
  const signOut = async () => {
    await client.clearStore()
    await auth.signOut()
  }
  const deleteUser = async () => {
    if (firebaseUser) {
      await firebaseUser.delete()
    }
  }
  const authCallback = async () => {
    if (auth.isSignInWithEmailLink(window.location.href)) {
      let email = window.localStorage.getItem(TEMPORARY_EMAIL_STORAGE)
      if (!email) {
        email = window.prompt('Please provide your email for confirmation')
      }
      await auth.signInWithEmailLink(email, window.location.href)
      history.push('/')
    }
  }

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn,
        login,
        loginGA,
        signOut,
        deleteUser,
        authCallback,
        initializing,
        firebaseUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const AuthRoute = (props) => {
  const { isLoggedIn } = useAuth()
  if (!isLoggedIn) {
    return <Redirect to="/login" />
  }
  return <Route {...props} />
}
