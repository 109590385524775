import React, { useState } from 'react'
import { useMutation } from '@apollo/client'
import { SEND_CODE, CONFIRM_CODE, SET_ONBOARDING_PHASE } from '../../graphql'

import Link from '../link'
import Button from '../button'
import Verify from '../verify'
import Provided from '../provided'

import { validate } from 'email-validator'

export default ({ user, phase, onComplete }) => {
  const [setOnboardingPhase, { loading }] = useMutation(SET_ONBOARDING_PHASE)
  const [sendCode, { loading: loadingSC }] = useMutation(SEND_CODE)
  const [confirmCode, { loading: loadingCC }] = useMutation(CONFIRM_CODE)

  const savedEmail = window.sessionStorage.getItem('pending')
  const [pending, changePending] = useState(Boolean(savedEmail))
  const [error, changeError] = useState(false)
  const [kindleEmail, changeKindleEmail] = useState(savedEmail || '')
  const [code, changeCode] = useState('')
  const [codeError, changeCodeError] = useState(false)

  async function handleCheck() {
    if (
      kindleEmail.length > 0 &&
      validate(kindleEmail) &&
      kindleEmail.endsWith('kindle.com')
    ) {
      changeError(false)
      try {
        await sendCode({
          variables: {
            email: kindleEmail,
          },
        })
        window.sessionStorage.setItem('pending', kindleEmail)
        changePending(true)
      } catch (e) {
        console.error(e)
      }
    } else {
      changeError(true)
    }
  }

  async function handleVerify(e) {
    e.preventDefault()
    if (code.length === 6) {
      changeCodeError(false)
      const { data } = await confirmCode({
        variables: {
          code,
          email: kindleEmail,
        },
      })
      if (data?.confirmCode) {
        window.sessionStorage.removeItem('pending')
        if (onComplete) {
          onComplete()
        } else {
          await setOnboardingPhase({
            variables: {
              phase: phase + 1,
            },
          })
        }
      } else {
        changeCodeError(true)
      }
    }
  }

  return (
    <section>
      <div>
        <ol className="list-decimal">
          <li>
            Go to:{' '}
            <Link
              className="link"
              href="https://www.amazon.com/hz/mycd/myx#/home/settings/payment"
            >
              Manage Your Content and Devices
            </Link>
            .
          </li>

          <li>
            Scroll down to <b>Personal Document Settings</b> and open the row.
          </li>
          <li>
            Under <b>Send-to-Kindle E-Mail Settings</b> please note the{' '}
            <b>E-mail address</b> (it should look like <i>example@kindle.com</i>
            ). Enter that email here:
            <div className="text-sm mt-4 mb-6">
              <label htmlFor="kindleEmail" className="label">
                Kindle Email Address
              </label>
              <input
                name="kindleEmail"
                value={kindleEmail}
                onChange={(e) => changeKindleEmail(e.target.value)}
                className="input w-full"
                placeholder="example@kindle.com"
              />
              {error && (
                <p className="mt-1 error-status">
                  Please enter a valid @kindle.com email.
                </p>
              )}
            </div>
          </li>
          <li>
            Under <b>Approved Personal Document Email List</b> select{' '}
            <b>Add a new approved e-mail address</b> and enter:
            <br />
            <Provided>{user.unique_email_id}</Provided>
          </li>
        </ol>
        <Verify onSubmit={handleCheck} loading={loadingSC} className="mt-8" />
      </div>
      {pending && (
        <div className="mt-8 lg:mt-12">
          <div className="p-8 card">
            <h3 className="text-2xl font-bold">Now on your Kindle</h3>
            <hr className="mt-2 mb-4" />
            <p className="text-sm md:text-base">
              A document has been sent to your Kindle with a confirmation code
              in it.{' '}
              <span className="font-bold">
                If you do not receive the code, please ensure you followed each
                step above carefully.
              </span>
            </p>

            <form onSubmit={handleVerify} className="mt-8 mb-4">
              <label htmlFor="code" className="label">
                Confirmation Code
              </label>
              <div className="flex items-center">
                <input
                  name="code"
                  className="input w-24"
                  maxLength={6}
                  value={code}
                  onChange={(e) => changeCode(e.target.value)}
                  placeholder="Code"
                  autoFocus
                />
                <Button
                  className="ml-4"
                  disabled={code.length !== 6}
                  type="submit"
                  loading={loading || loadingCC}
                >
                  Verify Code
                </Button>
              </div>
              {codeError && (
                <p className="mt-1 error-status">Code is incorrect.</p>
              )}
            </form>

            <p className="text-xs md:text-sm text-accent">
              If the code doesn&apos;t appear automatically, look under{' '}
              <b>Your Library</b> &gt; <b>Downloaded</b> on your Kindle.
            </p>
          </div>
        </div>
      )}
    </section>
  )
}
