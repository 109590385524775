import { auth } from './firebase'

import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'

const httpLink = createHttpLink({
  uri: '/.netlify/functions/graphql',
})

const authLink = setContext(async (_, { headers }) => {
  const token = await auth.currentUser.getIdToken()
  const combinedHeaders = {
    ...headers,
    authorization: token ? `Bearer ${token}` : '',
  }
  const viewAs = localStorage.getItem('viewas') || ''
  if (viewAs) {
    combinedHeaders.viewas = viewAs
  }
  return {
    headers: combinedHeaders,
  }
})

export const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(httpLink),
})
