import React from 'react'

import { PRICING } from '../utils'

function Status(props) {
  const { children } = props
  const { text, title } = PRICING[children]
  return <span className={`text-${text} font-bold`}>{title}</span>
}

export default Status
