import { gql } from '@apollo/client'

export const DELIVERY_FRAGMENT = gql`
  fragment deliveryFragment on Delivery {
    created_at
    message_id
    subject
    sent
    summary
  }
`

export const NEWSLETTER_FRAGMENT = gql`
  fragment newsletterFragment on Newsletter {
    created_at
    id
    is_newsletter_converted
    is_newsletter_active
    is_newsletter_hidden
    name
    owner_id
    source_email
    blocked
    delivery_count
    subscribed_default
    subscribed_email
  }
`

export const DOMAIN_FRAGMENT = gql`
  fragment domainFragment on Domain {
    created_at
    id
    source_email
    name
    is_paid
  }
`

export const PAYMENT_FRAGMENT = gql`
  fragment paymentFragment on Payment {
    premium_status
    ending
  }
`

export const RSS_FRAGMENT = gql`
  fragment rssFragment on Rss {
    created_at
    id
    name
    url
    invalid
    enabled
    hidden
    bundle_html
    convert_html
    blocked
    delivery_count
  }
`

export const USER_FRAGMENT = gql`
  fragment userFragment on User {
    id
    name
    kindle_email
    provided_email_address
    unique_email_id
    created_at
    payment {
      ...paymentFragment
    }
    onboarding_phase
    is_user_admin
    month_emails_sent
    emails_sent
    is_user_gmail
    legacy
    auto_confirmed
    newsletters {
      ...newsletterFragment
    }
    ignore_alerts
    prepend_sender
    rss_feeds {
      ...rssFragment
    }
    flags
    additional_email_addresses
  }
  ${PAYMENT_FRAGMENT}
  ${NEWSLETTER_FRAGMENT}
  ${RSS_FRAGMENT}
`

export const ADMIN = {
  USER_FRAGMENT: gql`
    fragment adminUserFragment on AdminUser {
      id
      name
      email
      newsletters {
        ...newsletterFragment
      }
      monthly
      total
      created_at
      onboarding_phase
      payment {
        ...paymentFragment
      }
    }
    ${PAYMENT_FRAGMENT}
    ${NEWSLETTER_FRAGMENT}
  `,
  EMAIL_FRAGMENT: gql`
    fragment adminEmailFragment on AdminEmail {
      subject
      to
      from
      created_at
    }
  `,
}
