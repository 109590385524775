import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { ADD_RSS } from '../../graphql'

import Button from '../button'

function CreateRss() {
  const history = useHistory()

  const [name, changeName] = useState('')
  const [url, changeUrl] = useState('')
  const [addRss, { loading }] = useMutation(ADD_RSS)

  async function handleSubmit(e) {
    e.preventDefault()
    await addRss({
      variables: {
        rss: {
          name,
          url,
        },
      },
    })
    history.push('/')
  }

  return (
    <form onSubmit={handleSubmit} className="p-4 card">
      <label htmlFor="name" className="label">
        RSS Feed Name
      </label>
      <div className="flex items-center">
        <input
          name="name"
          type="text"
          value={name}
          onChange={(e) => changeName(e.target.value)}
          className="w-full input"
          placeholder="Hacker News"
        />
      </div>

      <div className="py-4" />

      <label htmlFor="url" className="label">
        RSS Feed URL
      </label>
      <div className="flex items-center">
        <input
          name="url"
          type="text"
          value={url}
          onChange={(e) => changeUrl(e.target.value)}
          className="w-full input"
          placeholder="https://hnrss.org/newest"
        />
      </div>

      <Button type="submit" className="w-full mt-8 sm:w-32" loading={loading}>
        Submit
      </Button>
    </form>
  )
}

export default CreateRss
