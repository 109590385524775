import React, { useState } from 'react'
import { useLazyQuery, useMutation } from '@apollo/client'
import { SET_ONBOARDING_PHASE, ACTIVATE_USER } from '../../graphql'

import InputField from '../inputfield'
import Dialog from '../dialog'
import Button from '../button'
import Provided from '../provided'

function NewsletterQueue(props) {
  const { user, phase } = props
  const [open, setOpen] = useState(false)

  const [setOnboardingPhase, { loading }] = useMutation(SET_ONBOARDING_PHASE)
  const [activateUser, { loading: loadingAU }] = useLazyQuery(ACTIVATE_USER)

  function toggleOpen() {
    setOpen(!open)
  }

  async function handleSkip() {
    toggleOpen()
    await activateUser({
      variables: {
        skip_setup: true,
      },
    })
  }

  async function handleSubmit() {
    await setOnboardingPhase({
      variables: {
        phase: phase + 1,
      },
    })
  }

  return (
    <section>
      <h1 className="text-xl">Newsletter Setup</h1>
      <p className="text-sm text-accent mb-8">
        The rest of the setup is intended for Gmail users. If you don&apos;t use
        Gmail,{' '}
        <button
          className="inline-block link text-accent focus:outline-none"
          onClick={toggleOpen}
          disabled={loadingAU}
        >
          click here
        </button>
        .
      </p>
      <InputField {...props} onSubmit={handleSubmit} loading={loading} />

      <Dialog
        title="Non-Gmail Setup"
        open={open}
        onClose={toggleOpen}
        actions={[
          <Button onClick={handleSkip} loading={loadingAU}>
            Skip setup
          </Button>,
          <Button onClick={toggleOpen}>Cancel</Button>,
        ]}
      >
        <div>
          <p className="text-accent">
            If you don't use Gmail then you can use your provided email address
            to subscribe to newsletters directly. You may skip setup if you wish
            to do this.
          </p>
          <br />
          <p>
            <b>Provided Email:</b> <Provided>{user.unique_email_id}</Provided>
          </p>
          <br />
        </div>
      </Dialog>
    </section>
  )
}

export default NewsletterQueue
