import React from 'react'

import {
  Dialog as MuiDialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
} from '@material-ui/core'

function Dialog(props) {
  const { title, open, onClose, actions, children } = props
  return (
    <MuiDialog onClose={onClose} open={open} maxWidth="sm" fullWidth>
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent>
        {typeof children === 'string' ? (
          <DialogContentText>{children}</DialogContentText>
        ) : (
          children
        )}
      </DialogContent>
      {actions && (
        <DialogActions>
          {actions.map((action, i) => {
            return <React.Fragment key={i}>{action}</React.Fragment>
          })}
        </DialogActions>
      )}
    </MuiDialog>
  )
}

export default Dialog
