import React from 'react'
import { useState } from 'react'
import { useMutation } from '@apollo/client'
import { SET_NAME, SET_ONBOARDING_PHASE } from '../../graphql'

import Button from '../button'

function Naming(props) {
  const { phase } = props
  const [error, changeError] = useState(false)
  const [name, changeName] = useState('')
  const [referral, changeReferral] = useState('')
  const [setName, { loading }] = useMutation(SET_NAME)
  const [setOnboardingPhase, { loading: loadingP }] =
    useMutation(SET_ONBOARDING_PHASE)

  async function handleSubmit(e) {
    e.preventDefault()
    if (name.length === 0) {
      changeError(true)
    } else {
      await setName({
        variables: {
          name,
          referral,
        },
      })
      await setOnboardingPhase({
        variables: {
          phase: phase + 1,
        },
      })
    }
  }

  return (
    <section>
      <form onSubmit={handleSubmit}>
        <label htmlFor="name" className="label">
          What&apos;s your name?
        </label>
        <div className="flex items-center">
          <input
            name="name"
            type="text"
            value={name}
            onChange={(e) => changeName(e.target.value)}
            className="input w-full"
            placeholder="Your name..."
            autoFocus
          />
        </div>
        {error && <p className="mt-1 error-status">Please enter your name.</p>}

        <div className="py-4" />

        <label htmlFor="referral" className="label">
          Optional. Where did you hear about us?
        </label>
        <div className="flex items-center">
          <input
            name="referral"
            type="text"
            value={referral}
            onChange={(e) => changeReferral(e.target.value)}
            className="input w-full"
            placeholder="Google, Facebook, ProductHunt..."
          />
        </div>

        <Button
          type="submit"
          className="mt-8 w-full sm:w-32"
          loading={loading || loadingP}
        >
          Submit
        </Button>
      </form>
    </section>
  )
}

export default Naming
