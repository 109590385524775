import React from 'react'
import ReactLoading from 'react-loading'

import Logo from './logo'

function Loading() {
  return (
    <div className="absolute top-0 left-0 w-full h-screen flex flex-col justify-center items-center">
      <div className="mb-4">
        <Logo />
      </div>
      <ReactLoading type="spin" height={30} width={30} color="#000" />
    </div>
  )
}

export default Loading
