import React, { useState } from 'react'
import { Redirect } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { GET_USER } from '../graphql'

import {
  Home,
  User,
  HelpCircle,
  DollarSign,
  Menu,
  X,
  Database,
  Users,
  Rss,
  Cpu,
} from 'react-feather'

import Button from './button'
import Link from './link'
import SEO from './seo'
import Loading from './loading'
import Logo from './logo'
import Copy from './copy'

import { FLAGS, useAuth } from '../utils'

const links = [
  {
    name: 'Home',
    path: '/',
    notActive: true,
    Icon: Home,
  },
  {
    name: 'RSS',
    path: '/rss',
    notActive: false,
    Icon: Rss,
    flag: FLAGS.ENABLE_RSS,
  },
  {
    name: 'Summaries',
    path: '/summaries',
    notActive: false,
    Icon: Cpu,
    flag: FLAGS.ENABLE_EMAIL_SUMMARY,
  },
  {
    name: 'Profile',
    path: '/profile',
    notActive: false,
    Icon: User,
  },
  {
    name: 'Upgrade',
    path: '/upgrade',
    notActive: false,
    Icon: DollarSign,
  },
  {
    name: 'Help',
    path: '/help',
    notActive: false,
    Icon: HelpCircle,
  },
  {
    name: 'Admin',
    path: '/admin',
    Icon: Users,
    admin: true,
  },
  {
    name: 'SQL',
    path: '/sql',
    Icon: Database,
    admin: true,
  },
]

export default ({ title, header, description, flag, children }) => {
  const viewAs = localStorage.getItem('viewas') || ''
  const [toggle, setToggle] = useState(false)
  const [value, setValue] = useState(viewAs)

  const { signOut } = useAuth()
  const { loading, data } = useQuery(GET_USER)
  if (loading) {
    return <Loading />
  }
  const { id, onboarding_phase, is_user_admin, flags } = data?.getUser || {}

  if (flag && !flags.includes(flag)) {
    return <Redirect to="/" />
  }

  const isUserActive = onboarding_phase === -1

  function handleLogout() {
    signOut()
  }
  function handleToggle() {
    setToggle(!toggle)
  }
  function handleSubmit(e) {
    e.preventDefault()
    if (value.length === 0) {
      localStorage.removeItem('viewas')
    } else {
      localStorage.setItem('viewas', value)
    }
    window.location.reload()
  }

  return (
    <div className="relative min-h-screen">
      <SEO title={title} />
      <div
        className={`transform transition-200 fixed z-50 top-0 left-0 h-full w-72 bg-gray-50 ${
          !toggle && '-translate-x-72 lg:translate-x-0'
        }`}
      >
        <div className="flex flex-col h-full p-6 pb-8 border-r w-72">
          <Button className="w-full mb-6 lg:hidden" onClick={handleToggle}>
            <X className="inline-flex mr-1" />
            Close
          </Button>

          <Logo className="mb-12" />

          <nav className="flex-grow">
            {links.map(({ name, path, notActive, Icon, admin, flag }, i) => {
              if (admin === true && is_user_admin !== true) return null
              if (!isUserActive && notActive === false) return null
              if (flag && !flags.includes(flag)) return null
              return (
                <Link
                  key={i}
                  to={path}
                  className="flex items-center justify-start mb-2 bg-transparent btn hover:bg-black hover:bg-opacity-10"
                  activeClassName="bg-black bg-opacity-10 hover:bg-opacity-20"
                  nav
                  exact
                >
                  <Icon className="mr-2" />
                  {name}
                </Link>
              )
            })}
          </nav>
          <div>
            {process.env.NODE_ENV === 'development' && (
              <div className="mb-4">
                <Copy>{id}</Copy>
              </div>
            )}
            {(is_user_admin || viewAs.length > 0) && (
              <form onSubmit={handleSubmit} className="flex items-end mb-4">
                <div>
                  <label htmlFor="viewas" className="label">
                    View As (User ID or Email)
                  </label>
                  <input
                    name="viewas"
                    className="input"
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                  />
                </div>
                <button type="submit" className="ml-1 btn">
                  Go
                </button>
              </form>
            )}
            <Button onClick={handleLogout} className="w-full">
              Log out
            </Button>
            <p className="mt-2 text-xs text-center text-accent">
              Newsletter to Kindle &copy; {new Date().getFullYear()}
            </p>
          </div>
        </div>
      </div>
      <div className="flex">
        <div className="hidden lg:block w-72" />
        <div className="relative flex-grow h-full min-h-screen">
          <main className="pb-16">
            <div className="fixed top-0 left-0 flex items-center justify-between w-full px-4 py-2 lg:hidden">
              <button className="btn-icon" onClick={handleToggle}>
                <Menu />
              </button>
            </div>
            <div className="max-w-4xl px-6 mx-auto">
              {(header || description) && (
                <>
                  <div className="mt-16 lg:mb-16">
                    {header && (
                      <h1 className="text-4xl lg:text-6xl">{header}</h1>
                    )}
                    {description && (
                      <p className="mt-2 text-black text-opacity-50 lg:text-xl">
                        {description}
                      </p>
                    )}
                  </div>
                  <hr className="mt-4 mb-8 lg:hidden" />
                </>
              )}
              {children}
            </div>
          </main>
        </div>
      </div>
    </div>
  )
}
