import React from 'react'
import Tippy from '@tippyjs/react'
import { useMutation } from '@apollo/client'
import { UPDATE_RSS } from '../graphql'

import { MoreVertical } from 'react-feather'
import { Menu, MenuDivider, MenuHeader, MenuItem } from '@szhsin/react-menu'

import Dialog from './dialog'
import Origin from './origin'
import Deliveries from './deliveries'

function RssFeed(props) {
  const { user, rssFeed, open, onSelect } = props
  const {
    id,
    url,
    name,
    convert_html,
    blocked,
    enabled,
    hidden,
    delivery_count,
  } = rssFeed

  const [updateRss] = useMutation(UPDATE_RSS)

  const handleUpdate = async (id, key, value) => {
    await updateRss({
      variables: {
        input: {
          rss_id: id,
          [key]: value,
        },
      },
    })
  }

  const status = user.payment.premium_status
  const isFree = status === 'free' || !status

  return (
    <>
      <div className="flex items-center text-sm border-b hover:bg-gray-50 focus:outline-none">
        <div
          className="flex-grow py-4 ml-2"
          onClick={() => onSelect()}
          role="button"
        >
          <p className="font-bold">
            {name}
            <span className="ml-2 font-normal">{url}</span>
          </p>
          <p className="text-xs text-accent">
            {delivery_count} email{delivery_count !== 1 && 's'} sent, {blocked}{' '}
            email
            {blocked !== 1 && 's'} blocked
          </p>
        </div>

        <Menu
          menuButton={
            <button
              className="mr-2 btn-icon"
              onClick={(e) => e.stopPropagation()}
            >
              <MoreVertical />
            </button>
          }
          align="end"
          direction="bottom"
        >
          <MenuItem
            type="checkbox"
            checked={convert_html}
            onClick={(e) => handleUpdate(id, 'convert_html', e.checked)}
            disabled={isFree}
          >
            <Tippy content="Upgrade plan to enable feature" disabled={!isFree}>
              <div>Convert to Kindle format</div>
            </Tippy>
          </MenuItem>
          <MenuItem
            type="checkbox"
            checked={enabled}
            onClick={(e) => handleUpdate(id, 'enabled', e.checked)}
          >
            Active RSS Feed
          </MenuItem>
          <MenuItem
            type="checkbox"
            checked={hidden}
            onClick={(e) => handleUpdate(id, 'hidden', e.checked)}
          >
            Hide from dashboard
          </MenuItem>
          <MenuDivider />
          <MenuHeader>
            <span className="mr-2">Via</span>
            <Origin>RSS Page</Origin>
          </MenuHeader>
        </Menu>
      </div>
      <Dialog open={open} onClose={onSelect} title="Deliveries">
        <Deliveries user={user} columnId="rss_id" id={id} onSelect={onSelect} />
      </Dialog>
    </>
  )
}

export default RssFeed
