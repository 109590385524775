import React from 'react'
import { useQuery } from '@apollo/client'
import { GET_USER } from '../graphql'

import LovingDoodle from '../assets/images/doodles/LovingDoodle.png'

import {
  Dashboard,
  Error,
  Loading,
  Accordion,
  Provided,
  Link,
  Status,
} from '../components'
import { CheckCircle, Info } from 'react-feather'
import ForwardingSteps from '../components/onboarding/forwardingsteps'

export default () => {
  const { loading, data, error } = useQuery(GET_USER)
  const user = data?.getUser
  if (error) {
    return <Error error={error} />
  }
  if (!user || loading) {
    return <Loading />
  }
  return (
    <Dashboard
      title="Help"
      header="Need some help?"
      description="Email not getting received? Document not opening correctly on your
    device? Something else?"
    >
      <section>
        <h3 className="mb-4 text-xl">Setting up a newsletter</h3>
        <div className="text-sm lg:text-base">
          <Accordion title="Use the provided email address">
            <p className="flex items-center mb-8 text-sm text-accent">
              <Info className="w-4 h-4 mr-1" />
              This method allows you to subscribe to newsletters directly
            </p>
            <p>
              You can subscribe to newsletters using{' '}
              <Provided>{user.unique_email_id}</Provided>. Emails sent here will
              be automatically converted and sent to your Kindle.
            </p>
          </Accordion>
          <Accordion
            title="Set up automatic forwarding from Gmail"
            description={
              user.auto_confirmed ? (
                <span className="flex items-center">
                  <CheckCircle className="mr-2" />
                  You have set up automatic forwarding
                </span>
              ) : null
            }
          >
            <p className="flex items-center mb-8 text-sm text-accent">
              <Info className="w-4 h-4 mr-1" />
              This method allows you to forward incoming newsletters from your
              Gmail account to our platform
            </p>
            <ForwardingSteps user={user} />
          </Accordion>
          <Accordion title="Upgrade plan to Premium Pro for Gmail integration">
            <p className="flex items-center mb-8 text-sm text-accent">
              <Info className="w-4 h-4 mr-1" />
              This method allows you to set up forwarding filters automatically
              with Gmail
            </p>
            <ol className="list-decimal">
              <li>
                Visit the{' '}
                <Link to="/upgrade" className="link">
                  Upgrade
                </Link>{' '}
                page and upgrade your plan to <Status>{'pro'}</Status>
              </li>
              <li>
                Once upgraded, you can grant permission to watch for your
                incoming newsletters
              </li>
              <li>
                You can then choose which newsletters you want forwarded from a
                dropdown, or add it manually if you don&apos;t see it there
              </li>
              <li>
                Incoming newsletters that you specified in the previous step
                will automatically be forwarded to your Kindle
              </li>
            </ol>
          </Accordion>
        </div>
      </section>
      <section className="mt-8">
        <h3 className="mb-4 text-xl">Newsletter Delivery</h3>
        <Accordion title="Newsletter delivery shows up on dashboard but not on Kindle">
          <p className="flex items-center mb-8 text-sm text-accent">
            <Info className="w-4 h-4 mr-1" />
            If a newsletter you were expecting can be seen on the dashboard, you
            can send a retry attempt
          </p>
          <ol className="list-decimal">
            <li>
              Visit the{' '}
              <Link to="/" className="link">
                Home
              </Link>{' '}
              page
            </li>
            <li>Click on the newsletter with the missing delivery</li>
            <li>
              In the dialog modal, check to see that our system has received the
              newsletter. If not, contact us at{' '}
              <a href="mailto:help@newslettertokindle.com" className="link">
                help@newslettertokindle.com
              </a>
            </li>
            <li>
              To send a retry, click on the <b>Send again</b> button
            </li>
            <li>
              After a few minutes, check your Kindle to see if it is received.
              If not, contact us so we can help resolve your issue
            </li>
          </ol>
        </Accordion>
        <Accordion title="Newsletter delivery never arrived">
          <p className="flex items-center mb-8 text-sm text-accent">
            <Info className="w-4 h-4 mr-1" />
            If a newsletter you were expecting did not arrive, contact us
          </p>
          <p>
            If a newsletter that you have correctly set up forwarding for has
            not been received, please contact us at{' '}
            <a href="mailto:help@newslettertokindle.com" className="link">
              help@newslettertokindle.com
            </a>{' '}
            and we will take a look.
          </p>
          <br />
          <p>
            Please include the name of the newsletter, the newsletter email
            address, and the date/time you recieved the newsletter.
          </p>
        </Accordion>
      </section>
      <section className="mt-8">
        <p className="text-accent">
          If you are having problems or have any questions, please contact us at{' '}
          <a href="mailto:help@newslettertokindle.com" className="link">
            help@newslettertokindle.com
          </a>
          .
        </p>
        <img
          src={LovingDoodle}
          className="w-full max-w-lg"
          alt="loving doodle"
        />
      </section>
    </Dashboard>
  )
}
