import React, { useState } from 'react'
import moment from 'moment'
import { useMutation, useQuery } from '@apollo/client'
import { GET_DELIVERIES, SEND_AGAIN } from '../graphql'

import Toast from './toast'
import Button from './button'

import { FLAGS } from '../utils'

const Deliveries = ({ user, columnId, id, onSelect }) => {
  const { data, loading: loadingGD } = useQuery(GET_DELIVERIES, {
    variables: {
      columnId,
      id,
    },
  })

  const [sendAgain] = useMutation(SEND_AGAIN)
  const [loading, setLoading] = useState(null)
  const [toast, setToast] = useState(false)

  const viewAs = localStorage.getItem('viewas') || ''

  async function handleSend(messageId, sendToAdmin = false) {
    setLoading(messageId)
    await sendAgain({
      variables: {
        message_id: messageId,
        send_to_admin: sendToAdmin,
      },
    })
    setLoading(null)
    onSelect()
    setToast(true)
  }

  const deliveries = data?.getDeliveries ?? []
  const sortedDeliveries = deliveries
    .slice()
    .filter(
      (delivery) => process.env.NODE_ENV === 'development' || delivery.subject,
    )
    .sort()
    .sort((a, b) => {
      if (a.created_at < b.created_at) {
        return 1
      }
      if (a.created_at > b.created_at) {
        return -1
      }
      return 0
    })

  if (loadingGD) {
    return <p>Loading...</p>
  }

  return (
    <>
      {sortedDeliveries.length > 0
        ? sortedDeliveries.map((delivery, i) => {
            const { subject, created_at, message_id, summary } = delivery
            return (
              <div
                key={i}
                className="flex items-center justify-between p-2 border-t hover:bg-gray-50"
              >
                <div className="pr-2">
                  <p className="text-xs font-bold overflow-ellipsis">
                    {subject}
                  </p>
                  <p className="text-xs text-accent">
                    {moment(created_at).format('MM/DD/YY')}
                  </p>
                  {user.is_user_admin &&
                    user.flags.includes(FLAGS.ENABLE_EMAIL_SUMMARY) && (
                      <p className="mt-1 text-xs font-bold">
                        {summary && summary.length > 0 ? `YES` : `NO`} summary
                      </p>
                    )}
                </div>
                <div className="inline-flex justify-center text-sm">
                  {/* <Link href="#" className="mr-2 link">
                  View newsletter
                </Link> */}
                  <Button
                    onClick={() => handleSend(message_id, false)}
                    loading={loading === message_id}
                    className="px-2 py-1"
                  >
                    Send again
                  </Button>
                  {viewAs.length > 0 && (
                    <Button
                      onClick={() => handleSend(message_id, true)}
                      loading={loading === message_id}
                      className="px-2 py-1"
                    >
                      [ADMIN] Send to me
                    </Button>
                  )}
                </div>
              </div>
            )
          })
        : `You don't have any deliveries.`}
      <Toast
        open={toast}
        onClose={() => setToast(false)}
        message="Resent delivery"
      />
    </>
  )
}

export default Deliveries
