import React from 'react'
import { Redirect } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { GET_USER } from '../graphql'

import { Dashboard, Loading, GmailButton, Error } from '../components'

export default () => {
  const { loading, data, error } = useQuery(GET_USER)
  if (error) {
    return <Error error={error} />
  }
  if (loading) {
    return <Loading />
  }
  const { legacy, is_user_gmail } = data?.getUser
  if (legacy !== true) {
    return <Redirect to="/" />
  }
  return (
    <Dashboard
      title="Legacy"
      header="Legacy"
      description="Features for legacy users"
    >
      <section>
        <GmailButton disabled={is_user_gmail} />
      </section>
    </Dashboard>
  )
}
