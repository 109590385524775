import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'

import env from './env'
const firebaseConfig = {
  apiKey: env.REACT_APP_FIREBASE_API_KEY,
  authDomain: `${env.REACT_APP_FIREBASE_PROJECT_ID}.firebaseapp.com`,
  projectId: env.REACT_APP_FIREBASE_PROJECT_ID,
}

firebase.initializeApp(firebaseConfig)
const fbAuth = firebase.auth()

if (window.location.hostname === 'localhost') {
  fbAuth.useEmulator('http://localhost:9099/')
}

fbAuth.useDeviceLanguage()
export const googleProvider = new firebase.auth.GoogleAuthProvider()
export const auth = fbAuth
export const actionCodeSettings = {
  url: window.location.href,
  handleCodeInApp: true,
}
