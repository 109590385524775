import { gql } from '@apollo/client'

import * as FRAGMENTS from './fragment'

// Users

export const GET_USER = gql`
  query {
    getUser {
      ...userFragment
    }
  }
  ${FRAGMENTS.USER_FRAGMENT}
`
export const DELETE_SELF = gql`
  query {
    deleteSelf
  }
`
export const ACTIVATE_USER = gql`
  query {
    activateUser {
      ...userFragment
    }
  }
  ${FRAGMENTS.USER_FRAGMENT}
`

// Newsletters

export const GET_DEFAULTS = gql`
  query {
    getDefaults {
      ...domainFragment
    }
  }
  ${FRAGMENTS.DOMAIN_FRAGMENT}
`

// Deliveries

export const GET_DELIVERIES = gql`
  query ($columnId: String!, $id: ID!) {
    getDeliveries(columnId: $columnId, id: $id) {
      ...deliveryFragment
    }
  }
  ${FRAGMENTS.DELIVERY_FRAGMENT}
`

// Misc

export const LOGOUT_GMAIL = gql`
  query {
    logoutGmail {
      ...userFragment
    }
  }
  ${FRAGMENTS.USER_FRAGMENT}
`
export const CREATE_SESSION = gql`
  query ($status: String!, $page: String) {
    createSession(status: $status, page: $page)
  }
`
export const CREATE_PORTAL = gql`
  query ($page: String) {
    createPortal(page: $page)
  }
`
export const CANCEL_SUBSCRIPTION = gql`
  query {
    cancelSubscription
  }
`

// Admin

export const ADMIN_GET_USERS = gql`
  query {
    adminGetUsers {
      ...adminUserFragment
    }
  }
  ${FRAGMENTS.ADMIN.USER_FRAGMENT}
`
export const ADMIN_GET_EMAILS = gql`
  query ($startAt: Int) {
    adminGetEmails(startAt: $startAt) {
      emails {
        ...adminEmailFragment
      }
      start
      end
    }
  }
  ${FRAGMENTS.ADMIN.EMAIL_FRAGMENT}
`
