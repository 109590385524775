import React, { useState } from 'react'
import { Redirect } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { GET_USER } from '../graphql'

import {
  Dashboard,
  Loading,
  Button,
  Newsletter,
  RssFeed,
  Error,
  InputField,
} from '../components'

import { FLAGS } from '../utils'

const HomeList = (props) => {
  const { user, items, hiddenKey, type } = props

  const [open, setOpen] = useState(null)
  const [showAll, setShowAll] = useState(false)

  const notHiddenItems = []
  items.forEach((item) => {
    if (!item[hiddenKey]) {
      notHiddenItems.push(item)
    }
  })
  const displayed = showAll ? items : notHiddenItems
  const hiddenCount = items.length - notHiddenItems.length

  const term = type === 'rss' ? 'RSS feed' : 'newsletters'

  return (
    <>
      <div className="flex items-end justify-between pb-2 border-b">
        <p className="px-2 text-xs font-bold uppercase text-accent">{term}</p>
        {hiddenCount > 0 && (
          <Button onClick={() => setShowAll(!showAll)}>
            {showAll ? 'Hide' : 'Show'} hidden {term} ({hiddenCount})
          </Button>
        )}
      </div>

      {displayed.length > 0 ? (
        displayed.map((item, i) => {
          if (type === 'rss') {
            return (
              <RssFeed
                key={i}
                user={user}
                rssFeed={item}
                open={open === item.id}
                onSelect={() => setOpen(open === item.id ? null : item.id)}
              />
            )
          }
          return (
            <Newsletter
              key={i}
              user={user}
              newsletter={item}
              open={open === item.id}
              onSelect={() => setOpen(open === item.id ? null : item.id)}
            />
          )
        })
      ) : (
        <div className="p-2 text-sm text-accent">No items</div>
      )}
    </>
  )
}

export default () => {
  const { data, loading, error } = useQuery(GET_USER)
  if (error) {
    return <Error error={error} />
  }
  const user = data?.getUser
  if (!user || loading) {
    return <Loading />
  }
  if (user?.onboarding_phase > -1) {
    return <Redirect to="/onboarding" />
  }
  const newsletters = user.newsletters

  return (
    <Dashboard
      title="Home"
      header="My Newsletters"
      description={`Welcome back, ${user.name}!`}
    >
      <section>
        <HomeList
          user={user}
          items={newsletters}
          hiddenKey="is_newsletter_hidden"
          type="newsletter"
        />
      </section>

      {user.flags.includes(FLAGS.ENABLE_RSS) && (
        <section className="mt-12">
          <HomeList
            user={user}
            items={user.rss_feeds}
            hiddenKey="hidden"
            type="rss"
          />
        </section>
      )}

      <section className="mt-12">
        <div className="p-8 card">
          <h1 className="mb-8 text-xl">Newsletter Setup</h1>
          <InputField />
        </div>
      </section>
    </Dashboard>
  )
}
