import React, { useState } from 'react'

import { Link, SEO, Logo, Button } from '../components'

import { useAuth } from '../utils'
import { validate } from 'email-validator'

import GoogleIcon from '../assets/images/google.png'

const Email = () => {
  const { login } = useAuth()
  const [email, changeEmail] = useState('')
  const [error, changeError] = useState(false)
  const [loading, setLoading] = useState(false)
  async function handleSubmit(e) {
    setLoading(true)
    e.preventDefault()
    if (email.length > 0 && validate(email)) {
      changeError(false)
      await login(email)
      setLoading(false)
    } else {
      changeError(true)
      setLoading(false)
    }
  }
  return (
    <form noValidate onSubmit={handleSubmit}>
      <label htmlFor="email" className="label">
        Email
      </label>
      <input
        autoFocus
        name="email"
        type="email"
        onChange={(e) => changeEmail(e.target.value)}
        value={email}
        className="w-full input"
        placeholder="Enter your email address..."
      />
      {error && (
        <p className="mt-2 error-status">Please enter a valid email.</p>
      )}
      <Button className="w-full mt-4" type="submit" loading={loading}>
        Submit
      </Button>
    </form>
  )
}

const Password = ({ newUser }) => {
  const { login } = useAuth()
  const [email, changeEmail] = useState('')
  const [emailError, changeEmailError] = useState(false)
  const [password, changePassword] = useState('')
  const [passwordError, changePasswordError] = useState(false)
  const [authError, changeAuthError] = useState(null)
  const [loading, setLoading] = useState(false)
  async function handleSubmit(e) {
    setLoading(true)
    e.preventDefault()
    let emailErr = true
    let passErr = true
    if (email.length > 0 && validate(email)) {
      emailErr = false
    }
    if (password.length >= 6) {
      passErr = false
    }
    if (!emailErr && !passErr) {
      try {
        await login(email, password, newUser)
        setLoading(false)
      } catch (e) {
        changeAuthError(e.message)
      }
    }
    changeEmailError(emailErr)
    changePasswordError(passErr)
    setLoading(false)
  }
  return (
    <form noValidate onSubmit={handleSubmit}>
      {authError && <p className="mb-2 error-status">{authError}</p>}
      <label htmlFor="email" className="label">
        Email
      </label>
      <input
        autoFocus
        name="email"
        type="email"
        onChange={(e) => changeEmail(e.target.value)}
        value={email}
        className="w-full input"
        placeholder="Enter your email address..."
      />
      {emailError && (
        <p className="mt-2 error-status">Please enter a valid email.</p>
      )}
      <label htmlFor="password" className="mt-4 label">
        Password
      </label>
      <input
        name="password"
        type="password"
        onChange={(e) => changePassword(e.target.value)}
        value={password}
        className="w-full input"
        placeholder="Enter your password..."
      />
      {passwordError && (
        <p className="mt-2 error-status">
          Please enter a valid password (6+ characters).
        </p>
      )}
      <Button className="w-full mt-4" type="submit" loading={loading}>
        Submit
      </Button>
    </form>
  )
}

const Google = ({ newUser }) => {
  const { loginGA } = useAuth()
  return (
    <Button
      className="bg-white border shadow hover:bg-gray-100"
      onClick={loginGA}
    >
      <img
        src={GoogleIcon}
        className="w-5 h-5 mr-4 object-fit"
        alt="google icon"
      />
      {newUser ? 'Register' : 'Sign in'} with Google
    </Button>
  )
}

export default ({ newUser }) => {
  const [tab, changeTab] = useState(Number(localStorage.getItem('tab') || '0'))
  return (
    <div className="flex flex-col min-h-screen">
      <SEO title={newUser ? 'Register' : 'Log in'} />
      <header className="pt-4">
        <Logo className="justify-center" />
      </header>
      <main className="flex-grow">
        <section className="container pt-16 md:pt-24">
          <div className="max-w-md mx-auto">
            <h1 className="pb-4 mb-8 text-5xl text-center">
              {newUser ? 'Register' : 'Log in'}
            </h1>
            <div className="grid grid-cols-2 gap-4 my-4 text-sm">
              <Button
                className={`w-full ${
                  tab === 0 && 'bg-purple-200 hover:bg-purple-300'
                }`}
                onClick={() => {
                  localStorage.setItem('tab', '0')
                  changeTab(0)
                }}
              >
                Email/Password
              </Button>
              <Button
                className={`w-full ${
                  tab === 1 && 'bg-purple-200 hover:bg-purple-300'
                }`}
                onClick={() => {
                  localStorage.setItem('tab', '1')
                  changeTab(1)
                }}
              >
                Email a Magic Link
              </Button>
            </div>
            <div className="">
              {tab === 0 && <Password newUser={newUser} />}
              {tab === 1 && <Email />}
              <div className="flex items-center">
                <div className="flex-grow">
                  <hr />
                </div>
                <div className="text-center">
                  <p className="px-6 py-4 text-sm text-accent">or</p>
                </div>
                <hr className="flex-grow" />
              </div>
              <div className="flex items-center justify-center">
                <Google newUser={newUser} />
              </div>
            </div>

            <p className="mt-12 text-xs text-center text-accent">
              By clicking “Submit” above, you acknowledge that you have read and
              understood, and agree to our{' '}
              <Link
                href="https://newslettertokindle.com/docs/terms-of-service"
                className="link"
              >
                Terms of Service
              </Link>{' '}
              and{' '}
              <Link
                href="https://newslettertokindle.com/docs/privacy-policy"
                className="link"
              >
                Privacy Policy
              </Link>
              .
            </p>
          </div>
        </section>
        <section className="mt-8 text-center">
          {newUser ? (
            <p>
              Already have an account?{' '}
              <Link to="/login" className="link">
                Log in
              </Link>
            </p>
          ) : (
            <p>
              Don&apos;t have an account?{' '}
              <Link to="/register" className="link">
                Register
              </Link>
            </p>
          )}
        </section>
      </main>
      <footer className="container py-2">
        <p className="text-sm text-center text-accent">
          Newsletter to Kindle &copy; {new Date().getFullYear()}
        </p>
      </footer>
    </div>
  )
}
