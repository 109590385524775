import React from 'react'

import Snackbar from '@material-ui/core/Snackbar'

import Button from './button'

function Toast(props) {
  const { open, onClose, message } = props
  return (
    <Snackbar
      message={message}
      open={open}
      autoHideDuration={6000}
      onClose={onClose}
      action={
        <React.Fragment>
          <Button onClick={onClose}>OK</Button>
        </React.Fragment>
      }
    />
  )
}

export default Toast
