import React from 'react'
import { useMutation } from '@apollo/client'
import { INIT_GMAIL } from '../graphql'

import { useGoogleLogin } from '@react-oauth/google'
import { Button } from '../components'
import { env } from '../utils'
import GoogleIcon from '../assets/images/google.png'

export default ({ disabled, syncFilters, className }) => {
  const [initGmail, { loading }] = useMutation(INIT_GMAIL)

  async function onSuccess(response) {
    if (response?.code) {
      await initGmail({
        variables: {
          code: response.code,
          sync_filters: syncFilters,
        },
      })
    }
  }

  const login = useGoogleLogin({
    clientId: env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID,
    scope:
      'https://www.googleapis.com/auth/gmail.readonly https://www.googleapis.com/auth/gmail.settings.basic https://www.googleapis.com/auth/gmail.labels',
    onSuccess,
    onError: (e) => {
      console.error(e)
    },
    flow: 'auth-code',
  })

  return (
    <Button
      className={`bg-white hover:bg-black hover:bg-opacity-5 border shadow ${className}`}
      onClick={login}
      loading={loading}
      disabled={disabled}
    >
      <img
        src={GoogleIcon}
        className="w-5 h-5 mr-4 object-fit"
        alt="google icon"
      />
      Connect with Gmail
    </Button>
  )
}
