import React, { useEffect, useMemo, useState } from 'react'
import moment from 'moment'
import { useQuery, useLazyQuery, useMutation } from '@apollo/client'
import {
  GET_USER,
  DELETE_SELF,
  LOGOUT_GMAIL,
  SET_ONBOARDING_PHASE,
  SET_IGNORE_ALERTS,
  SET_PREPEND_SENDER,
  ADD_ADDITIONAL_EMAIL_ADDRESS,
  DELETE_ADDITIONAL_EMAIL_ADDRESS,
} from '../graphql'

import {
  Dashboard,
  Loading,
  Provided,
  Button,
  Status,
  Accordion,
  Dialog,
  GmailButton,
  Error as ErrorComponent,
  Toast,
} from '../components'
import { PRICING, useAuth } from '../utils'

import { KindleSetup } from '../components'
import { FormControlLabel, Switch } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { validate } from 'email-validator'
import UpgradeButton from '../components/upgradebutton'
import { AlertTriangle } from 'react-feather'

const EmailAlerts = ({ ignoreAlerts, ignoreAlertsParam }) => {
  const [toast, setToast] = useState(false)
  const history = useHistory()

  const [setIgnoreAlerts, { loading: loadingIA }] =
    useMutation(SET_IGNORE_ALERTS)

  async function handleAlertsChange() {
    const newValue = !ignoreAlerts
    await setIgnoreAlerts({
      variables: {
        value: newValue,
      },
    })
    setToast(`Email alerts are turned ${newValue ? 'off' : 'on'}`)
  }

  useEffect(() => {
    if (ignoreAlertsParam === 'true') {
      if (ignoreAlerts !== true) {
        handleAlertsChange()
      }
      history.push('/profile')
    }
  }, [])

  return (
    <>
      <p className="mb-4 text-accent">
        We send occasional emails to notify you about your account status. If
        you would like to turn these off, please enable the switch below.
      </p>
      <FormControlLabel
        control={
          <Switch
            disabled={loadingIA}
            checked={Boolean(ignoreAlerts)}
            onChange={handleAlertsChange}
            inputProps={{ 'aria-label': 'controlled' }}
            color="default"
          />
        }
        label="Turn off email alerts"
      />
      <Toast open={toast} onClose={() => setToast(null)} message={toast} />
    </>
  )
}

const PrependSender = ({ prependSender }) => {
  const [toast, setToast] = useState(false)

  const [setPrependSender, { loading: loadingPS }] =
    useMutation(SET_PREPEND_SENDER)

  async function handleSenderChange() {
    const newValue = !prependSender
    await setPrependSender({
      variables: {
        value: newValue,
      },
    })
    setToast(`Prepending the sender is turned ${newValue ? 'on' : 'off'}`)
  }

  return (
    <>
      <p className="mb-4 text-accent">
        {`By default, the title of the document being sent to your Kindle matches
        the incoming newsletter subject. If you would prefer to have it be
        "[{SENDER}] {SUBJECT}" to easily see who the sender is, enable this
        setting.`}
      </p>
      <FormControlLabel
        control={
          <Switch
            disabled={loadingPS}
            checked={Boolean(prependSender)}
            onChange={handleSenderChange}
            inputProps={{ 'aria-label': 'controlled' }}
            color="default"
          />
        }
        label="Enable prepending sender"
      />
      <Toast open={toast} onClose={() => setToast(null)} message={toast} />
    </>
  )
}

const AdditionalEmails = ({ additionalEmailAddresses }) => {
  const [addAdditionalEmailAddress] = useMutation(ADD_ADDITIONAL_EMAIL_ADDRESS)
  const [deleteAdditionalEmailAddress] = useMutation(
    DELETE_ADDITIONAL_EMAIL_ADDRESS,
  )

  const [loading, setLoading] = useState(undefined)
  const [value, setValue] = useState('')
  const [error, setError] = useState(undefined)

  const handleDelete = (emailAddress) => {
    return async () => {
      const conf = window.confirm(
        `Are you sure you'd like to delete this email address?`,
      )
      if (!conf) return
      setLoading('button')
      await deleteAdditionalEmailAddress({
        variables: {
          emailAddress,
        },
      })
      setLoading(undefined)
    }
  }

  async function handleSubmit(e) {
    setLoading('input')
    e.preventDefault()
    try {
      if (value.length === 0 || !validate(value)) {
        throw new Error('Please enter a valid email address')
      }
      setError(undefined)
      await addAdditionalEmailAddress({
        variables: {
          emailAddress: value,
        },
      })
      setLoading(undefined)
      setValue('')
    } catch (e) {
      console.error(e)
      setError(e.message)
      setLoading(undefined)
    }
  }

  return (
    <>
      <p className="mb-8 text-accent">
        If you wish to forward newsletters from other email accounts other than
        the&nbsp;<b>Email Address</b>&nbsp;above to your&nbsp;
        <b>Provided Email</b> you can add them here.
      </p>

      <p className="pb-2 mb-2 text-xs font-bold uppercase border-b text-accent">
        Additional email addresses
      </p>
      {additionalEmailAddresses.length > 0 ? (
        <div>
          <ul className="list-disc">
            {additionalEmailAddresses.map((emailAddress, i) => {
              return (
                <li key={i}>
                  {emailAddress}
                  <button
                    className="ml-2 text-xs link"
                    onClick={handleDelete(emailAddress)}
                    disabled={loading === 'button'}
                  >
                    Delete
                  </button>
                </li>
              )
            })}
          </ul>
        </div>
      ) : (
        <p className="text-accent text-sm">
          You have not added any additional email addresses yet.
        </p>
      )}

      <form onSubmit={handleSubmit} className="mt-2">
        <div className="items-center mt-4 text-sm sm:flex">
          <input
            type="email"
            name="manual"
            className="w-full text-sm input sm:w-72"
            placeholder="Enter the email address..."
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
          <Button
            className="mt-2 sm:mt-0 sm:ml-2"
            type="submit"
            loading={loading === 'input'}
          >
            Add to list
          </Button>
        </div>
        {error && <p className="mt-2 error-status">{error}</p>}
      </form>
    </>
  )
}

export default (props) => {
  const qp = new URLSearchParams(props.location.search)
  const ignoreAlertsParam = qp.get('ignore_alerts')

  const initialOpen = ignoreAlertsParam === 'true' ? 2 : 0
  const [open, setOpen] = useState(initialOpen)

  const [openD, setOpenD] = useState(false)
  const [openGD, setOpenGD] = useState(false)

  const { signOut } = useAuth()
  const { loading, data, error } = useQuery(GET_USER)
  const [deleteSelf, { loading: loadingD }] = useLazyQuery(DELETE_SELF)
  const [logoutGmail, { loading: loadingL }] = useLazyQuery(LOGOUT_GMAIL)
  const [setOnboardingPhase] = useMutation(SET_ONBOARDING_PHASE)

  // need this up here because of early returns
  const resetDate = useMemo(() => {
    if (!data?.getUser?.created_at) return moment()
    const today = moment()
    const createdAt = moment(data.getUser.created_at)
    createdAt.month(today.month())
    createdAt.year(today.year())
    if (createdAt.day() >= today.day()) {
      createdAt.add(1, 'month')
    }

    return createdAt
  }, [data?.getUser?.created_at])

  if (error) {
    return <ErrorComponent error={error} />
  }
  const user = data?.getUser
  if (!user || loading) {
    return <Loading />
  }
  const {
    provided_email_address,
    unique_email_id,
    created_at,
    payment,
    month_emails_sent,
    emails_sent,
    name,
    is_user_gmail,
    kindle_email,
    ignore_alerts,
    prepend_sender,
    additional_email_addresses,
    id,
    is_user_admin,
  } = user
  const isPro = payment.premium_status === 'pro'
  const createdDate = moment(created_at)
  const days = moment().diff(createdDate, 'days') + 1
  const viewAs = localStorage.getItem('viewas') || ''

  function toggleOpenD() {
    setOpenD(!openD)
  }
  function toggleOpenGD() {
    setOpenGD(!openGD)
  }

  async function handleDelete() {
    await deleteSelf()
    await signOut()
  }
  async function handleDisconnect() {
    await logoutGmail()
    toggleOpenGD()
  }

  async function dev__resetOnboarding() {
    await setOnboardingPhase({
      variables: {
        phase: 0,
      },
    })
  }

  const numEmails = PRICING[payment.premium_status].numEmails
  const { title, border } = PRICING.plus

  return (
    <Dashboard title="Profile" header="Profile">
      <section>
        {numEmails === month_emails_sent && (
          <div className="bg-yellow-100 border-2 border-yellow-600 rounded-lg p-4 mb-4 text-lg">
            <div className="text-yellow-600 flex items-center gap-2">
              <AlertTriangle />
              <span>{`You've reached the ${numEmails} monthly email limit for this plan.`}</span>
            </div>
            <div className="mt-2 text-base">
              <UpgradeButton
                status="plus"
                className={`text-white bg-${border} hover:bg-opacity-80`}
                ending={payment.ending}
                plan={payment.premium_status}
                baseUrl="/profile"
              >
                {title}
              </UpgradeButton>
            </div>
          </div>
        )}

        <ul className="list-disc">
          {(is_user_admin || viewAs.length > 0) && (
            <li>
              <b>[Admin] ID</b> {id}
            </li>
          )}
          <li>
            <b>Email Address:</b> {provided_email_address}
          </li>
          <li>
            <b>Name:</b> {name}
          </li>
          <li>
            <b>Provided Email:</b> <Provided>{unique_email_id}</Provided>
          </li>
          <li className="align-middle">
            <b>Account Created:</b> {createdDate.format('MM/DD/YY')}{' '}
            <span className="text-sm text-purple-600">
              ({days} day
              {days === 1 ? '' : 's'} old)
            </span>
          </li>
          <li>
            <b>Account Plan:</b> <Status>{payment.premium_status}</Status>
          </li>
          <li className="align-middle">
            <b>Emails Sent This Month:</b> {month_emails_sent}
            {PRICING[payment.premium_status].numEmails !== null && (
              <span>
                {' '}
                <span className="text-sm text-purple-600">
                  (resets {resetDate.format('MM/DD/YY')})
                </span>
              </span>
            )}
          </li>
          <li>
            <b>Emails Allowed This Month:</b>{' '}
            {PRICING[payment.premium_status].numEmails !== null
              ? PRICING[payment.premium_status].numEmails
              : 'unlimited'}
          </li>
          <li>
            <b>Total Emails Sent:</b> {emails_sent}
          </li>
          <li>
            <b>Kindle Email:</b> {kindle_email}
          </li>
        </ul>
        <div className="mt-2">
          <Accordion
            title="Change your Kindle Email"
            expanded={open === 1}
            setExpanded={() => setOpen(open === 1 ? 0 : 1)}
          >
            <KindleSetup
              user={data?.getUser}
              onComplete={() => window.location.reload()}
            />
          </Accordion>
          <Accordion
            title="Change email notifications"
            expanded={open === 2}
            setExpanded={() => setOpen(open === 2 ? 0 : 2)}
          >
            <EmailAlerts
              ignoreAlerts={ignore_alerts}
              ignoreAlertsParam={ignoreAlertsParam}
            />
          </Accordion>
          <Accordion
            title="Prepend sender in document title"
            expanded={open === 3}
            setExpanded={() => setOpen(open === 3 ? 0 : 3)}
          >
            <PrependSender prependSender={prepend_sender} />
          </Accordion>
          <Accordion
            title="Send from additional email addresses"
            expanded={open === 4}
            setExpanded={() => setOpen(open === 4 ? 0 : 4)}
          >
            <AdditionalEmails
              additionalEmailAddresses={additional_email_addresses}
            />
          </Accordion>
        </div>
        <div className="flex items-center mt-8">
          <Button onClick={toggleOpenD} loading={loadingD}>
            Delete Account
          </Button>

          {(isPro || is_user_gmail) && (
            <>
              {is_user_gmail ? (
                <Button
                  className="ml-2"
                  onClick={toggleOpenGD}
                  loading={loadingL}
                >
                  Disconnect Gmail
                </Button>
              ) : (
                <GmailButton className="ml-2" />
              )}
            </>
          )}

          {process.env.NODE_ENV === 'development' && (
            <Button className="ml-2" onClick={dev__resetOnboarding}>
              [DEV] Reset Onboarding
            </Button>
          )}
        </div>
      </section>
      <Dialog
        title="Account Deletion"
        open={openD}
        onClose={toggleOpenD}
        actions={[
          <Button onClick={handleDelete}>Confirm</Button>,
          <Button onClick={toggleOpenD}>Cancel</Button>,
        ]}
      >
        Are you sure you wish to delete your account? This cannot be undone. All
        existing subscriptions will be cancelled.
      </Dialog>
      <Dialog
        title="Gmail Disconnect"
        open={openGD}
        onClose={toggleOpenGD}
        actions={[
          <Button onClick={handleDisconnect}>Confirm</Button>,
          <Button onClick={toggleOpenGD}>Cancel</Button>,
        ]}
      >
        Are you sure you wish to disconnect from Gmail?
      </Dialog>
    </Dashboard>
  )
}
