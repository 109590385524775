import React, { useState } from 'react'
import { useMutation } from '@apollo/client'
import { SET_ONBOARDING_PHASE } from '../../graphql'

import ForwardingSteps from './forwardingsteps'
import Verify from '../verify'
import GmailButton from '../gmailbutton'
import Status from '../status'

function GmailSetup(props) {
  const { phase, user } = props
  const [preferGmail, setPreferGmail] = useState(true)
  const [setOnboardingPhase, { loading }] = useMutation(SET_ONBOARDING_PHASE)

  function togglePrefer() {
    setPreferGmail(!preferGmail)
  }

  async function handleSubmit() {
    await setOnboardingPhase({
      variables: {
        phase: phase + 1,
      },
    })
  }

  async function goBack() {
    await setOnboardingPhase({
      variables: {
        phase: phase - 1,
      },
    })
  }

  const isPro = user.payment.premium_status === 'pro'
  const isFree = user.payment.premium_status === 'free'
  const isGmail = user.is_user_gmail === true

  if (isPro && isGmail && process.env.NODE_ENV !== 'development') {
    handleSubmit()
  }

  return (
    <section>
      {isPro && preferGmail ? (
        <>
          <h3 className="text-xl">
            Thank you for upgrading to <Status>{'pro'}</Status>
          </h3>
          <p className="text-sm text-accent mb-12">
            You can now integrate directly with Gmail to import your newsletter
            filters. If you&apos;d prefer to not use Gmail{' '}
            <button
              className="inline-block link text-accent focus:outline-none"
              onClick={togglePrefer}
            >
              click here
            </button>
            .
          </p>
          <GmailButton syncFilters className="w-full" />
          <p className="mt-8 text-accent text-xs">
            Once you click on "Connect with Gmail", Google will alert you that
            we&apos;re not a verified app. We&apos;re currently in the process
            of being verified. Click on <b>Advanced</b> to continue. If you have
            any questions, contact us at{' '}
            <a href="mailto:help@newslettertokindle.com" className="link">
              help@newslettertokindle.com
            </a>
          </p>
        </>
      ) : (
        <>
          <h3 className="text-xl">Setting up Gmail Forwarding</h3>
          {isPro && (
            <p className="text-sm text-accent">
              If you&apos;d prefer to integrate directly with Gmail{' '}
              <button
                className="inline-block link text-accent focus:outline-none"
                onClick={togglePrefer}
              >
                click here
              </button>
              .
            </p>
          )}
          <ForwardingSteps phase={1} user={user} className="mt-8" />
          <Verify onSubmit={handleSubmit} loading={loading} />
          {isFree && (
            <button
              className="mt-8 text-xs link text-accent focus:outline-none"
              onClick={goBack}
              disabled={loading}
            >
              Take me back, I want to see the plans again
            </button>
          )}
        </>
      )}
    </section>
  )
}

export default GmailSetup
