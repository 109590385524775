import React from 'react'

import ReactLoading from 'react-loading'

export default ({ disabled, loading, className, children, ...props }) => {
  return (
    <button
      className={`btn relative ${className}`}
      disabled={disabled || loading}
      {...props}
    >
      <div
        className={`absolute top-0 left-0 w-full h-full flex items-center justify-center ${
          loading ? 'visible' : 'invisible'
        }`}
      >
        <ReactLoading
          type="spin"
          height={20}
          width={20}
          color="#000"
          className=""
        />
      </div>
      <div className={`${loading ? 'invisible' : 'visible'} flex items-center`}>
        {children}
      </div>
    </button>
  )
}
