const {
  REACT_APP_GOOGLE_OAUTH_CLIENT_ID,
  REACT_APP_GOOGLE_ANALYTICS_ID,
  REACT_APP_GOOGLE_TAG_ID,
  REACT_APP_STRIPE_PUBLISHABLE_KEY,
  REACT_APP_FIREBASE_API_KEY,
  REACT_APP_FIREBASE_PROJECT_ID,
  REACT_APP_HEROKU_URL,
  REACT_APP_FACEBOOK_PIXEL,
} = process.env

export default Object.freeze({
  REACT_APP_GOOGLE_OAUTH_CLIENT_ID,
  REACT_APP_GOOGLE_ANALYTICS_ID,
  REACT_APP_GOOGLE_TAG_ID,
  REACT_APP_STRIPE_PUBLISHABLE_KEY,
  REACT_APP_FIREBASE_API_KEY,
  REACT_APP_FIREBASE_PROJECT_ID,
  REACT_APP_HEROKU_URL,
  REACT_APP_FACEBOOK_PIXEL,
})
