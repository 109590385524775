import React from 'react'
import { Link, NavLink } from 'react-router-dom'

import { ExternalLink } from 'react-feather'

export default ({ to, href, nav, children, ...props }) => {
  const { className, ...rest } = props
  if (href) {
    return (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        className={`inline-flex items-center ${className}`}
        {...rest}
      >
        {children} <ExternalLink className="inline mb-2 align-top" size={10} />
      </a>
    )
  }
  if (nav) {
    return (
      <NavLink to={to} {...props}>
        {children}
      </NavLink>
    )
  }
  return (
    <Link to={to} {...props}>
      {children}
    </Link>
  )
}
