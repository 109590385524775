import React, { useEffect, useState } from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { ApolloProvider } from '@apollo/client'
import { env, AuthProvider, AuthRoute, useAuth, client } from './utils'
import {
  HomePage,
  LoginPage,
  ProfilePage,
  HelpPage,
  UpgradePage,
  AdminPage,
  RegisterPage,
  RssPage,
  SummariesPage,
  LegacyPage,
  OnboardingPage,
  SqlPage,
  ReadyPage,
} from './pages'

import ReactGA from 'react-ga4'

const NotFound = () => <Redirect to="/" />
const Routing = () => {
  const { initializing, authCallback } = useAuth()
  authCallback()
  if (initializing) {
    return null
  }
  return (
    <Switch>
      <AuthRoute exact path="/" component={HomePage} />
      <AuthRoute exact path="/onboarding" component={OnboardingPage} />
      <AuthRoute exact path="/ready" component={ReadyPage} />
      <Route exact path="/login" component={LoginPage} />
      <Route exact path="/register" component={RegisterPage} />
      <AuthRoute exact path="/rss" component={RssPage} />
      <AuthRoute exact path="/summaries" component={SummariesPage} />
      <AuthRoute exact path="/profile" component={ProfilePage} />
      <AuthRoute exact path="/help" component={HelpPage} />
      <AuthRoute exact path="/upgrade" component={UpgradePage} />
      <AuthRoute exact path="/legacy" component={LegacyPage} />
      <AuthRoute exact path="/admin" component={AdminPage} admin />
      <AuthRoute exact path="/sql" component={SqlPage} admin />
      <Route component={NotFound} />
    </Switch>
  )
}

export default () => {
  const [mounted, setMounted] = useState(false)
  useEffect(() => {
    if (!mounted) {
      ReactGA.initialize(env.REACT_APP_GOOGLE_TAG_ID)
      const ReactPixel = require('react-facebook-pixel')
      ReactPixel.default.init(env.REACT_APP_FACEBOOK_PIXEL)
      setMounted(true)
    }
  }, [mounted])
  return (
    <GoogleOAuthProvider clientId={env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID}>
      <ApolloProvider client={client}>
        <Router>
          <AuthProvider>
            <Routing />
          </AuthProvider>
        </Router>
      </ApolloProvider>
    </GoogleOAuthProvider>
  )
}
