import React from 'react'
import { useQuery } from '@apollo/client'
import { GET_USER } from '../graphql'

import { Dashboard, Loading, Error } from '../components'
import { FLAGS } from '../utils'
import CreateRss from '../components/rss/CreateRss'

export default () => {
  const { data, loading, error } = useQuery(GET_USER)
  if (error) {
    return <Error error={error} />
  }
  const user = data?.getUser
  if (!user || loading) {
    return <Loading />
  }

  const { rss_feeds } = user
  const feeds = [...rss_feeds]
  // order by `created_at`
  feeds.sort((a, b) => {
    return new Date(a.created_at) - new Date(b.created_at)
  })

  return (
    <Dashboard
      title="RSS"
      header="RSS Feeds"
      description="Subscribe to an RSS feed"
      flag={FLAGS.ENABLE_RSS}
    >
      <section>
        <CreateRss />
      </section>
    </Dashboard>
  )
}
