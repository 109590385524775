import React from 'react'
import { useQuery, useLazyQuery } from '@apollo/client'
import { GET_USER, CANCEL_SUBSCRIPTION } from '../graphql'

import moment from 'moment'

import { Dashboard, Loading, Button, Status, Plan, Error } from '../components'

const Checkout = ({ payment }) => {
  return (
    <div>
      {payment && payment.ending > 0 && (
        <div className="inline-block p-4 card mb-8">
          <p className="text-sm">
            Your paid plan is ending on{' '}
            <span className="font-bold">
              {moment.unix(payment.ending).format('MM/DD/YY')}
            </span>
            . You will not be charged any further.
          </p>
        </div>
      )}
      <div className="lg:grid grid-cols-3 gap-2">
        <Plan payment={payment} variant="free" baseUrl="/upgrade" />
        <Plan payment={payment} variant="plus" baseUrl="/upgrade" />
        <Plan payment={payment} variant="pro" baseUrl="/upgrade" />
      </div>
    </div>
  )
}

const LegacyCheckout = ({ payment }) => {
  const [cancelSubscription, { loading }] = useLazyQuery(CANCEL_SUBSCRIPTION)
  async function handleCancel() {
    await cancelSubscription()
    window.location.reload()
  }
  return (
    <div>
      <h1 className="text-3xl">
        You are already <Status>{'premium'}</Status>
      </h1>
      <p className="text-accent mb-4">
        Want to cancel at the end of your next pay cycle?
      </p>
      {payment.ending > 0 && (
        <div className="inline-block p-4 card mb-4">
          <p className="text-sm">
            Your paid plan is ending on{' '}
            <span className="font-bold">
              {moment.unix(payment.ending).format('MM/DD/YY')}
            </span>
            . You will not be charged any further.
          </p>
        </div>
      )}
      <Button
        onClick={handleCancel}
        loading={loading}
        disabled={payment?.ending > 0}
      >
        Cancel Premium
      </Button>
    </div>
  )
}

export default () => {
  const { loading, data, error } = useQuery(GET_USER)
  if (error) {
    return <Error error={error} />
  }
  const user = data?.getUser
  if (!user || loading) {
    return <Loading />
  }
  const { payment } = user
  return (
    <Dashboard
      title="Upgrade"
      header="Go Premium"
      description="Upgrade your account to have unlimited newsletters every month"
    >
      <section>
        {payment.premium_status === 'premium' ? (
          <LegacyCheckout payment={payment} />
        ) : (
          <Checkout payment={payment} />
        )}
      </section>
    </Dashboard>
  )
}
