import React from 'react'

import Helmet from 'react-helmet'

import globals from '../globals.json'

export default ({ description, keywords, title }) => {
  const stage = process.env.NODE_ENV === 'development' ? '[DEV] ' : ''
  return (
    <Helmet>
      <title>
        {title
          ? `${stage}${title} | ${globals.title}`
          : `${stage}${globals.title}`}
      </title>
      <meta
        name="description"
        content={description ? description : globals.description}
      />
      <meta
        name="keywords"
        content={keywords ? keywords.join(',') : globals.keywords.join(',')}
      />
    </Helmet>
  )
}
