import React from 'react'

import Copy from './copy'

function Provided(props) {
  const { children } = props
  const full = `${children}@newslettertokindle.com`
  return <Copy>{full}</Copy>
}

export default Provided
